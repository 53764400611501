import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "../elements/Buttons";

import LogoImage from "../images/quickinsights-logo-black.png";



// Main container with styling
const Container = styled.div`
  position: relative;
  background-color: #f1f1f1; /* bg-gray-200 */
  color: #4a4a4a; /* text-gray-700 */
  margin-bottom: -2rem; /* -mb-8 */
  margin-left: -2rem; /* -mx-8 */
  margin-right: -2rem; /* -mx-8 */
  padding: 2rem; /* px-8 */
`;

// Content container with max-width and centering
const Content = styled.div`
  max-width: 1200px; /* max-w-screen-xl */
  margin: 0 auto;
  position: relative;
  z-index: 10;
`;

// Divider for spacing
const Divider = styled.div`
  margin-top: 4rem; /* my-16 */
  margin-bottom: 4rem; /* my-16 */
  border-bottom: 2px solid #07c692; /* border-b-2 border-[#07c692] */
  width: 100%;
  transition: border-color 0.3s;
  &:hover {
    border-color: #07c692; /* hover:border-[#07c692] */
  }
`;

// Row layout for logo and copyright
const ThreeColRow = styled.div`
  display: flex;
  flex-direction: column; /* flex-col */
  @media (min-width: 768px) {
    flex-direction: row; /* md:flex-row */
  }
  align-items: center; /* items-center */
  justify-content: space-between; /* justify-between */
`;

// Logo section container
const LogoContainer = styled.div`
  display: flex;
  align-items: center; /* items-center */
  justify-content: center; /* justify-center */
  @media (min-width: 768px) {
    justify-content: flex-start; /* md:justify-start */
  }
`;

// Logo image styling
const LogoImg = styled.img`
  width: 15rem; /* w-62 */
  padding-top: 1rem; /* py-4 */
`;



// Copyright Notice styling
const CopywrightNotice = styled.p`
  text-align: center; /* text-center */
  font-size: 0.875rem; /* text-sm */
  margin-top: 2rem; /* mt-8 */
  @media (min-width: 768px) {
    font-size: 1rem; /* sm:text-base */
    margin-top: 0; /* md:mt-0 */
  }
  font-weight: 500; /* font-medium */
  color: #6b6b6b; /* text-gray-500 */
`;


export default () => {
  return (
    <Container>
      <Content>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CopywrightNotice>&copy; 2024 QuickInsights. All Rights Reserved.</CopywrightNotice>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
