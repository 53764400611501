import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../elements/Headings.js";
import { Container, ContentWithPaddingXl } from "../elements/Layouts.js";
import IllustrationImageSrc from "../images/QI-Response.png";
import { ReactComponent as StarIconBase } from "../images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "../images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../images/arrow-right-3-icon.svg";
import ProfileImage1 from "../images/profile1.png";
import ProfileImage2 from "../images/profile2.png";
import ProfileImage3 from "../images/profile3.png";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled(Column)`
  ${tw`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`}
  img {
    ${tw`w-full`}
    max-width: 100%;
    height: auto;
  }
`;

const TextColumn = styled(Column)`
  ${tw`md:w-7/12 mt-16 md:mt-0 p-4`} 
  ${props =>
    props.textOnLeft
      ? tw`md:mr-12 lg:mr-16 md:order-first`
      : tw`md:p-12 lg:p-16 md:order-last`}
  @media (max-width: 768px) {
    ${tw`text-center`} 
    padding: 0 16px;
  }
`;


const Image = styled.img`
  ${props => props.$imageRounded && tw`rounded`}
  ${props => props.$imageBorder && tw`border`}
  ${props => props.$imageShadow && tw`shadow`}
`;

const Subheading = styled(SubheadingBase)`
  ${tw`text-center md:text-left`}
  color: #07c692; // Main color
`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-[#333] text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonials = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-[#FFA500] mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl `;
const CustomerTitle = tw.p`font-medium text-secondary-100 `;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  justify-content: space-between; // Space out buttons
  .divider {
    ${tw`my-3 border-r`}
    border: 2px solid #07c692;
    &:hover {
      border-color: #0a9a7c;
    }
  }
`;

const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-[#07c692] hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  border: 2px solid #07c692;
  &:hover {
    border-color: #0a9a7c;
  }
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

const ResponsiveRow = styled(Row)`
  ${tw`flex flex-col md:flex-row justify-between items-center`}
  @media (max-width: 768px) {
    ${tw`flex-col items-center`}
    ${ImageColumn} {
      ${tw`w-full`}
      img {
        ${tw`w-full`} 
        margin: 0 auto; 
      }
    }
    ${TextColumn} {
      ${tw`w-full mt-8`} 
      padding: 0 16px; 
    }
  }
`;

export default ({
 imageSrc = IllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "What Our Users Say",
  heading = "Empowering Support Managers with QuickInsights",
  description = "Discover how QuickInsights has transformed the way support managers engage with their customers. Our users share their success stories and experiences with our AI-driven extension.",
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      profileImageSrc:  ProfileImage1, 
      heading: "Game Changer for Customer Support",
      quote:
        "QuickInsights has revolutionized our support process. The AI responses are accurate and timely, allowing us to assist customers more efficiently. Highly recommend!",
      customerName: "Charlotte Hale",
      customerTitle: "CEO, Delos Inc."
    },
    {
      stars: 5,
      profileImageSrc: ProfileImage2, 
      heading: "Incredible AI Assistance!",
      quote:
        "As a support agent, QuickInsights has made my job so much easier. The insights provided by the extension help me understand customer queries better and respond faster.",
      customerName: "Adam Cuppy",
      customerTitle: "Founder, EventsNYC"
    },
    {
      stars: 5,
      profileImageSrc: ProfileImage3, 
      heading: "Fantastic User Experience!",
      quote:
        "The interface is intuitive and the AI suggestions are spot-on. QuickInsights is an essential tool for anyone in customer support!",
      customerName: "Emily Tran",
      customerTitle: "Head of Customer Support, TechSavvy"
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
      <Container id="testimonials">
        <ContentWithPaddingXl>
          <ResponsiveRow>
            <ImageColumn>
              <Image
                src={imageSrc}
                $imageBorder={imageBorder}
                $imageShadow={imageShadow}
                $imageRounded={imageRounded}
              />
         </ImageColumn>
            <TextColumn $textOnLeft={textOnLeft}>
              <Subheading>{subheading}</Subheading>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <TestimonialSlider arrows={false} ref={setSliderRef}>
                {testimonials.map((testimonial, index) => (
                  <Testimonials key={index}>
                    <StarsContainer>
                      {Array.from({ length: testimonial.stars }).map((_, indexIcon) => (
                        <StarIcon key={indexIcon} />
                      ))}
                    </StarsContainer>
                    <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                    <Quote>{testimonial.quote}</Quote>
                    <CustomerInfoAndControlsContainer>
                      <CustomerInfo>
                        <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} />
                        <CustomerTextInfo>
                          <CustomerName>{testimonial.customerName}</CustomerName>
                          <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                        </CustomerTextInfo>
                      </CustomerInfo>
                      <Controls>
                        <ControlButton onClick={sliderRef?.slickPrev}>
                          <ArrowLeftIcon />
                        </ControlButton>
                        <div className="divider" />
                        <ControlButton onClick={sliderRef?.slickNext}>
                          <ArrowRightIcon />
                        </ControlButton>
                      </Controls>
                    </CustomerInfoAndControlsContainer>
                  </Testimonials>
                ))}
              </TestimonialSlider>
            </TextColumn>
          </ResponsiveRow>
        </ContentWithPaddingXl>
      </Container>
 
  );
};
