import React from 'react';
import { useUser } from '../../context/UserContext';
import './Feedback.css';

const Feedback = () => {
  const { user, isSignedIn, signIn } = useUser();

  // Google Form Base URL (replace with the pre-filled email field parameter)
  const googleFormBaseUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdUHjFPwbtwcFXU6sG0j2iyV5iBSfP5Qz4fNgfnZkizEPXhxw/viewform?usp=sf_link";

  // Pre-fill the email field if the user is signed in
  const googleFormUrl = isSignedIn
    ? `${googleFormBaseUrl}&entry.1591633300=${encodeURIComponent(user.email)}` // Replace `123456` with your actual entry ID for the email field
    : googleFormBaseUrl;

  return (
    <div className="feedback-container">
      <div className="feedback-header">
      <h2>We Value Your Feedback</h2>
      </div>
      {isSignedIn ? (
        // Display Google Form iframe with pre-filled email parameter
        <iframe
          src={googleFormUrl}
          width="100%"
          height="600"
          frameBorder="0"
          title="Feedback Form"
          className="feedback-form"
        >
          Loading…
        </iframe>
      ) : (
        <div>
          <p>Please sign in to submit feedback.</p>
          <button onClick={signIn} className="feedback-button">
            Sign In
          </button>
        </div>
      )}
    </div>
  );
};

export default Feedback;
