import React, { useState, useEffect } from 'react';
import './EnterpriseSettings.css'; // Import the CSS file for enterprise settings

const EnterpriseSettings = ({ 
    fileAttachmentsEnabled, 
    setFileAttachmentsEnabled, 
    multipleResponsesEnabled, 
    setMultipleResponsesEnabled, 
    mediaInsightsEnabled, 
    setMediaInsightsEnabled 
}) => {
    return (
        <div className="enterprise-settings-container">

            {/* Group for File Attachments */}
            <div className="group-container">
                <div className="group-title">
                    <h4>File Attachments</h4>
                    <p>Enable the use of file attachments in conversations.</p>
                </div>

                <label className="toggle-label" htmlFor="fileAttachmentsToggle">
                    <span className={`toggle ${fileAttachmentsEnabled ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${fileAttachmentsEnabled ? 'on' : 'off'}`}>
                        {fileAttachmentsEnabled ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="fileAttachmentsToggle"
                        checked={fileAttachmentsEnabled}
                        onChange={(e) => setFileAttachmentsEnabled(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>
            
            {/* Group for Media Insights */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Media Insights</h4>
                    <p>Enable related media suggestions based on QI's output.</p>
                </div>

                <label className="toggle-label" htmlFor="mediaInsightsToggle">
                    <span className={`toggle ${mediaInsightsEnabled ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${mediaInsightsEnabled ? 'on' : 'off'}`}>
                        {mediaInsightsEnabled ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="mediaInsightsToggle"
                        checked={mediaInsightsEnabled}
                        onChange={(e) => setMediaInsightsEnabled(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>

            {/* Group for Multiple Responses */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Multiple Responses</h4>
                    <p>Enable multiple responses functionality for advanced use cases.</p>
                </div>

                <label className="toggle-label" htmlFor="multipleResponsesToggle">
                    <span className={`toggle ${multipleResponsesEnabled ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${multipleResponsesEnabled ? 'on' : 'off'}`}>
                        {multipleResponsesEnabled ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="multipleResponsesToggle"
                        checked={multipleResponsesEnabled}
                        onChange={(e) => setMultipleResponsesEnabled(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>
        </div>
    );
};

export default EnterpriseSettings;
