import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '../../context/UserContext'; 
import './settings.css';
import FreeSettings from './components-settings/FreeSettings/FreeSettings';
import BasicSettings from './components-settings/BasicSettings/BasicSettings';
import ProSettings from './components-settings/ProSettings/ProSettings'; 
import EnterpriseSettings from './components-settings/EnterpriseSettings/EnterpriseSettings';


const Settings = () => {
    const { user, userPlan, isSignedIn, signIn } = useUser();
    const [settingsLoaded, setSettingsLoaded] = useState(false);

    const planHierarchy = ['Free', 'Basic', 'Pro', 'Enterprise']; // Plan hierarchy
    const plans = [
        { plan: 'Free Settings', description: 'Basic features for Free users' },
        { plan: 'Basic Settings', description: 'Additional features for Basic users' },
        { plan: 'Pro Settings', description: 'Advanced features for Pro users' },
        { plan: 'Enterprise Settings', description: 'All features for Enterprise users' },
    ];

    const isBlurred = (plan) => {
        const userPlanIndex = planHierarchy.indexOf(userPlan);
        const planIndex = planHierarchy.indexOf(plan.split(' ')[0]); // Get plan name
        return planIndex > userPlanIndex;
    };

    const [expandedCards, setExpandedCards] = useState(
        plans.map(plan => !isBlurred(plan.plan))
    );


    const [sliderValue, setSliderValue] = useState(0);
    const [wordCountEnabled, setWordCountEnabled] = useState(false);
    const [wordCount, setWordCount] = useState('');
    const [selectedTone, setSelectedTone] = useState('None'); // State for text tone
    const [textStyle, setTextStyle] = useState({
        complexity: 'None',
        voice: 'None',
    });
    const [notificationsEnabled, setNotificationsEnabled] = useState(true); // State for notifications
    const [selectedAIContext, setSelectedAIContext] = useState('None'); // State for AI context settings (Pro)
    const [fullPageInsights, setFullPageInsightsToggle] = useState(false); 
    const [qiSearchBar, setQiSearchBarToggle] = useState(false); 
    const [gmailReplies, setGmailRepliesToggle ] = useState(false); 
    const [fileAttachmentsEnabled, setFileAttachmentsEnabled] = useState(false); 
    const [mediaInsightsEnabled, setMediaInsightsEnabled] = useState(false); 
    

    const [multipleResponsesEnabled, setMultipleResponsesEnabled] = useState(false); 
    const [saveMessages, setSaveMessages] = useState(Array(plans.length).fill(''));
    const [isLoading, setIsLoading] = useState(true);
    const [fileId, setFileId] = useState(null); 
    const [previousUserPlan, setPreviousUserPlan] = useState(null);


    useEffect(() => {
        if (userPlan) {
            resetSettingsForHigherPlans();
        }
    }, [userPlan]);
    
    const defaultSettings = {
        selectedTone: 'None',
        textStyle: { complexity: 'None', voice: 'None' },
        selectedAIContext: 'None',
        fullPageInsights: false,
        qiSearchBar: false,
        gmailReplies: false,
        fileAttachmentsEnabled: false,
        mediaInsightsEnabled: false,
        multipleResponsesEnabled: false,
        notificationsEnabled: true,
    };
    
    const resetSettingsForHigherPlans = (currentSettings = {}) => {
        const userPlanIndex = planHierarchy.indexOf(userPlan);
        const previousPlanIndex = planHierarchy.indexOf(previousUserPlan); // Track previous plan
        
        // Create a copy of the current settings to avoid direct mutation
        const updatedSettings = { ...currentSettings };
        
        // Check if the user upgraded or downgraded
        const isUpgrade = userPlanIndex < previousPlanIndex;
        
        // Iterate over the plan hierarchy
        planHierarchy.forEach((plan, index) => {
            // If upgrading, reset only the settings for the current plan
           /* if (isUpgrade && index === userPlanIndex) {
                switch (plan) {
                    case "Enterprise":
                        updatedSettings.fileAttachmentsEnabled = defaultSettings.fileAttachmentsEnabled;
                        updatedSettings.multipleResponsesEnabled = defaultSettings.multipleResponsesEnabled;
                        break;
    
                    case "Pro":
                        updatedSettings.fullPageInsights = defaultSettings.fullPageInsights;
                        updatedSettings.gmailReplies = defaultSettings.gmailReplies;
                        updatedSettings.qiSearchBar = defaultSettings.qiSearchBar;
                        updatedSettings.selectedAIContext = defaultSettings.selectedAIContext;
                        break;
    
                    case "Basic":
                        // Explicitly reset settings to None for Basic plan
                        updatedSettings.selectedTone = defaultSettings.selectedTone;
                        updatedSettings.textStyle = defaultSettings.textStyle;
                        break;
    
                    default:
                        break;
                }
            }*/
    
            // Reset settings for higher-tier plans if downgrading
            if (index > userPlanIndex) {
                switch (plan) {
                    case "Enterprise":
                        updatedSettings.fileAttachmentsEnabled = defaultSettings.fileAttachmentsEnabled;
                        updatedSettings.mediaInsightsEnabled = defaultSettings.mediaInsightsEnabled;
                        updatedSettings.multipleResponsesEnabled = defaultSettings.multipleResponsesEnabled;
                        break;
    
                    case "Pro":
                        updatedSettings.fullPageInsights = defaultSettings.fullPageInsights;
                        updatedSettings.gmailReplies = defaultSettings.gmailReplies;
                        updatedSettings.qiSearchBar = defaultSettings.qiSearchBar;
                        updatedSettings.selectedAIContext = defaultSettings.selectedAIContext;
                        break;
    
                    case "Basic":
                        updatedSettings.selectedTone = defaultSettings.selectedTone;
                        updatedSettings.textStyle = defaultSettings.textStyle;
                        break;
    
                    default:
                        break;
                }
            }
        });
    
        return updatedSettings;
    };
    
    
    useEffect(() => {
        if (userPlan && previousUserPlan !== userPlan) {
            const initialSettings = resetSettingsForHigherPlans({
                sliderValue,
                wordCountEnabled,
                wordCount,
                notificationsEnabled,
                selectedTone,
                textStyle,
                selectedAIContext,
                fullPageInsights,
                qiSearchBar,
                gmailReplies,
                fileAttachmentsEnabled,
                mediaInsightsEnabled,
                multipleResponsesEnabled,
            });
            setPreviousUserPlan(userPlan);
    
            setSliderValue(initialSettings.sliderValue ?? sliderValue);
            setWordCountEnabled(initialSettings.wordCountEnabled ?? wordCountEnabled);
            setWordCount(initialSettings.wordCount ?? wordCount);
            setSelectedTone(initialSettings.selectedTone ?? selectedTone);
            setNotificationsEnabled(initialSettings.notificationsEnabled ?? notificationsEnabled);
            setSelectedAIContext(initialSettings.selectedAIContext ?? selectedAIContext);
            setFullPageInsightsToggle(initialSettings.fullPageInsights ?? fullPageInsights);
            setQiSearchBarToggle(initialSettings.qiSearchBar ?? qiSearchBar);
            setGmailRepliesToggle(initialSettings.gmailReplies ?? gmailReplies);
            setFileAttachmentsEnabled(initialSettings.fileAttachmentsEnabled ?? fileAttachmentsEnabled);
            setMediaInsightsEnabled(initialSettings.mediaInsightsEnabled ?? mediaInsightsEnabled);
            setMultipleResponsesEnabled(initialSettings.multipleResponsesEnabled ?? multipleResponsesEnabled);
            setTextStyle(initialSettings.textStyle ?? textStyle);
        }
    }, [userPlan]);
    
    
    const [ws, setWs] = useState(null);

    useEffect(() => {
        const connectWebSocket = () => {
            const websocket = new WebSocket('wss://server.quickinsights-ai.com'); // Replace with server URL
    
            websocket.onopen = () => {
                console.log('WebSocket connection established');
            };
    
            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.action === 'settingsUpdated') {
                    const updatedSettings = resetSettingsForHigherPlans(data.settings);
                    console.log('Settings updated via WebSocket:', updatedSettings);
    
                    // Update local state
                    setSliderValue(updatedSettings.sliderValue ?? sliderValue);
                    setWordCountEnabled(updatedSettings.wordCountEnabled ?? wordCountEnabled);
                    setWordCount(updatedSettings.wordCount ?? wordCount);
                    setSelectedTone(updatedSettings.selectedTone ?? selectedTone);
                    setNotificationsEnabled(updatedSettings.notificationsEnabled ?? notificationsEnabled);
                    setSelectedAIContext(updatedSettings.selectedAIContext ?? selectedAIContext);
                    setFullPageInsightsToggle(updatedSettings.fullPageInsights ?? fullPageInsights);
                    setQiSearchBarToggle(updatedSettings.qiSearchBar ?? qiSearchBar);
                    setGmailRepliesToggle(updatedSettings.gmailReplies ?? gmailReplies);
                    setFileAttachmentsEnabled(updatedSettings.fileAttachmentsEnabled ?? fileAttachmentsEnabled);
                    setMediaInsightsEnabled(updatedSettings.mediaInsightsEnabled ?? mediaInsightsEnabled);
                    setMultipleResponsesEnabled(updatedSettings.multipleResponsesEnabled ?? multipleResponsesEnabled);
                    setTextStyle(updatedSettings.textStyle ?? textStyle);
                } else if (data.action === 'logout') {
                    console.log('User logged out via WebSocket');
                    // Optionally, handle logout logic
                }
            };
    
            websocket.onerror = (error) => {
                console.error('WebSocket error in dashboard:', error);
            };
    
            websocket.onclose = () => {
                console.log('WebSocket connection closed, reconnecting...');
                setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
            };
    
            setWs(websocket);
        };
    
        // Initially connect the WebSocket
        connectWebSocket();
    
        return () => {
            if (ws) {
                ws.close(); // Clean up WebSocket connection on component unmount
            }
        };
    }, [userPlan]);
    
   
    
    const handleSave = async (plan, index) => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            signIn();
            return;
        }
    
        const userSettings = resetSettingsForHigherPlans({
            sliderValue,
            wordCountEnabled,
            wordCount,
            selectedTone,
            notificationsEnabled,
            selectedAIContext,
            fullPageInsights,
            qiSearchBar,
            gmailReplies,
            fileAttachmentsEnabled,
            mediaInsightsEnabled,
            multipleResponsesEnabled,
            textStyle,
        });
        // Check if the file exists in Google Drive
        const fileName = `settings_${user.id}.json`;
        const fileId = await checkIfFileExists(fileName, accessToken);
        
        const fileMetadata = {
            name: fileName,
            mimeType: 'application/json',
        };
    
        const media = {
            mimeType: 'application/json',
            body: JSON.stringify(userSettings),
        };
    
        try {
            const url = fileId
                ? `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart`
                : `https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`;
    
            const method = fileId ? 'PATCH' : 'POST';
    
            const form = new FormData();
            form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
            form.append('file', new Blob([media.body], { type: media.mimeType }));
    
            await fetch(url, {
                method: method,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: form,
            });
    
            const newMessages = [...saveMessages];
            newMessages[index] = `${plan} changes saved to your GDrive.`;
            setSaveMessages(newMessages);
    
            setTimeout(() => {
                const updatedMessages = [...saveMessages];
                updatedMessages[index] = '';
                setSaveMessages(updatedMessages);
            }, 3000);
    
            // Send updated settings to WebSocket server
            sendSettingsUpdate(userSettings);
    
        } catch (error) {
            console.error('Error saving settings to Google Drive:', error);
        }
    };

    



    
    const loadSettingsFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken'); 
        if (!accessToken) {
            signIn(); // Sign in if token is not available
            return;
        }
        setIsLoading(true); 
    
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="settings_${user.id}.json"&mimeType="application/json"`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
            
    
            // Check if files are returned and data is not null
            if (data && data.files && data.files.length > 0) {
                const fileId = data.files[0].id; // Access the id of the first file
                setFileId(fileId);
    
                const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
    
                if (!fileResponse.ok) {
                    throw new Error('Failed to fetch settings data');
                }
    
                const savedSettings = await fileResponse.json();


                setSliderValue(savedSettings.sliderValue ?? sliderValue);
                setWordCountEnabled(savedSettings.wordCountEnabled ?? wordCountEnabled);
                setWordCount(savedSettings.wordCount ?? wordCount);
                setSelectedTone(savedSettings.selectedTone ?? selectedTone);
                setNotificationsEnabled(savedSettings.notificationsEnabled ?? notificationsEnabled);
                setSelectedAIContext(savedSettings.selectedAIContext ?? selectedAIContext);
                setFullPageInsightsToggle(savedSettings.fullPageInsights ?? fullPageInsights);
                setQiSearchBarToggle(savedSettings.qiSearchBar ?? qiSearchBar);
                setGmailRepliesToggle(savedSettings.gmailReplies ?? gmailReplies);
                setFileAttachmentsEnabled(savedSettings.fileAttachmentsEnabled ?? fileAttachmentsEnabled);
                setMediaInsightsEnabled(savedSettings.mediaInsightsEnabled ?? mediaInsightsEnabled);
                setMultipleResponsesEnabled(savedSettings.multipleResponsesEnabled ?? multipleResponsesEnabled);
                setTextStyle(savedSettings.textStyle ?? textStyle);

    
     /*           // Apply `resetSettingsForLowerPlan` only to higher-tier settings
                const mergedSettings = resetSettingsForHigherPlans(savedSettings);
    
                // Update local states without overwriting existing settings
                setSliderValue(mergedSettings.sliderValue ?? savedSettings.sliderValue ?? sliderValue);
                setWordCountEnabled(mergedSettings.wordCountEnabled ?? savedSettings.wordCountEnabled ?? wordCountEnabled);
                setWordCount(mergedSettings.wordCount ?? savedSettings.wordCount ?? wordCount);
                setSelectedTone(mergedSettings.selectedTone ?? savedSettings.selectedTone ?? selectedTone);
                setNotificationsEnabled(mergedSettings.notificationsEnabled ?? savedSettings.notificationsEnabled ?? notificationsEnabled);
                setSelectedAIContext(mergedSettings.selectedAIContext ?? savedSettings.selectedAIContext ?? selectedAIContext);
                setFullPageInsightsToggle(mergedSettings.fullPageInsights ?? savedSettings.fullPageInsights ?? fullPageInsights);
                setQiSearchBarToggle(mergedSettings.qiSearchBar ?? savedSettings.qiSearchBar ?? qiSearchBar);
                setGmailRepliesToggle(mergedSettings.gmailReplies ?? savedSettings.gmailReplies ?? gmailReplies);
                setFileAttachmentsEnabled(mergedSettings.fileAttachmentsEnabled ?? savedSettings.fileAttachmentsEnabled ?? fileAttachmentsEnabled);
                setMediaInsightsEnabled(mergedSettings.mediaInsightsEnabled ?? savedSettings.mediaInsightsEnabled ?? mediaInsightsEnabled);
                setMultipleResponsesEnabled(mergedSettings.multipleResponsesEnabled ?? savedSettings.multipleResponsesEnabled ?? multipleResponsesEnabled);
                setTextStyle(mergedSettings.textStyle ?? savedSettings.textStyle ?? textStyle);
    */
                // Optionally send updated settings back to WebSocket or sync with storage
                sendSettingsUpdate(savedSettings);
                setSettingsLoaded(true);
            } else {
                console.error('No settings file found in Google Drive');
                setFileId(null); // Handle case where no file is found
            }
        } catch (error) {
            console.error('Error loading settings from Google Drive:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        if (isSignedIn && user && !settingsLoaded) {
            checkIfSettingsExist();
        } else {
            setIsLoading(false); // Stop loading if user is not signed in
        }
    }, [user, isSignedIn, settingsLoaded]);

    // Function to check if settings exist for the user
    const checkIfSettingsExist = async () => {
        const accessToken = localStorage.getItem('accessToken'); 
        if (!accessToken) {
            signIn();
            return;
        }

        try {
            const response = await fetch(
                `https://www.googleapis.com/drive/v3/files?q=name="settings_${user.id}.json"&mimeType="application/json"`,
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );
            const data = await response.json();

            if (data.files && data.files.length > 0) {
                await loadSettingsFromGoogleDrive(data.files[0].id);
            }
        } catch (error) {
            console.error('Error checking for settings file:', error);
        } finally {
            setSettingsLoaded(true);
            setIsLoading(false);
        }
    };



    const sendSettingsUpdate = (updatedData = {}) => {
        const updatedSettings = {
            sliderValue,
            wordCountEnabled,
            wordCount,
            selectedTone,
            notificationsEnabled,
            selectedAIContext,
            fullPageInsights,
            qiSearchBar,
            gmailReplies,
            fileAttachmentsEnabled,
            mediaInsightsEnabled,
            multipleResponsesEnabled,
            textStyle,
            ...updatedData,
        };

        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ action: 'settingsUpdated', settings: updatedSettings }));
        } 
    };

    
    
    // Helper function to check if the file exists
    const checkIfFileExists = async (fileName, accessToken) => {
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
            if (data.files && data.files.length > 0) {
                return data.files[0].id; // Return the file ID if it exists
            } else {
                return null; // File does not exist
            }
        } catch (error) {
            console.error('Error checking file existence:', error);
            return null; // Handle errors by returning null
        }
    }; 
    

    const toggleCard = (index) => {
        if (isBlurred(plans[index].plan)) {
            return;
        }
        setExpandedCards(prev => {
            const newExpandedCards = [...prev];
            newExpandedCards[index] = !newExpandedCards[index];
            return newExpandedCards;
        });
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    // Function to render loading state
    const renderLoadingState = () => (
        <div className="loading-container">
            <div className="loading-spinner"></div>
        </div>
    );

    const renderCardsAndButtons = () => {
        return plans.map((planData, index) => {
            const isExpanded = expandedCards[index];
            const blurred = isBlurred(planData.plan);

            return (
                <div key={index} className="card-container">
                    <div
                        className={`card ${blurred ? 'blurred' : ''}`}
                        onClick={() => toggleCard(index)}
                    >
                        <div className="card-header">
                            <h3>{planData.plan}</h3>
                            <button className="save-button" onClick={(e) => {
                                e.stopPropagation(); // Prevent the card toggle when saving
                                handleSave(planData.plan, index); // Pass index to handleSave
                            }} disabled={blurred}>
                                Save
                            </button>
                        </div>
                        <div className="card-content" onClick={handleContentClick}>
                            {isExpanded && !blurred ? (
                                      planData.plan === 'Free Settings' ? (
                                        <FreeSettings 
                                            sliderValue={sliderValue}
                                            setSliderValue={setSliderValue}
                                            wordCountEnabled={wordCountEnabled}
                                            setWordCountEnabled={setWordCountEnabled}
                                            wordCount={wordCount}
                                            setWordCount={setWordCount}
                                            notificationsEnabled={notificationsEnabled} 
                                            setNotificationsEnabled={setNotificationsEnabled} // Function to update notifications
                                        />
                                    ) : planData.plan === 'Basic Settings' ? (
                                        <BasicSettings 
                                            sendSettingsUpdate={sendSettingsUpdate}
                                            selectedTone={selectedTone}
                                            setSelectedTone={setSelectedTone}
                                            textStyle={textStyle} // Pass text style to BasicSettings
                                            setTextStyle={setTextStyle} // Function to update text style
                                        />
                                    ) : planData.plan === 'Pro Settings' ? (
                                        <ProSettings
                                            sendSettingsUpdate={sendSettingsUpdate}
                                            selectedAIContext={selectedAIContext}
                                            setSelectedAIContext={setSelectedAIContext}
                                            fullPageInsights={fullPageInsights} 
                                            setFullPageInsightsToggle={setFullPageInsightsToggle}
                                            qiSearchBar={qiSearchBar} 
                                            setQiSearchBarToggle={setQiSearchBarToggle}
                                            gmailReplies={gmailReplies} 
                                            setGmailRepliesToggle={setGmailRepliesToggle}
                                        />
                                    )  : planData.plan === 'Enterprise Settings' ? (
                                        <EnterpriseSettings
                                            sendSettingsUpdate={sendSettingsUpdate}
                                            fileAttachmentsEnabled={fileAttachmentsEnabled}
                                            setFileAttachmentsEnabled={setFileAttachmentsEnabled}
                                            mediaInsightsEnabled={mediaInsightsEnabled}
                                            setMediaInsightsEnabled={setMediaInsightsEnabled}
                                            multipleResponsesEnabled={multipleResponsesEnabled} 
                                            setMultipleResponsesEnabled={setMultipleResponsesEnabled}
                                        />
                                    ) : (
                                    <p>Additional features for {planData.plan}</p>
                                )
                            ) : null}
                        </div>
                        {saveMessages[index] && (
                            <p className="save-message">{saveMessages[index]}</p>
                        )}
                    </div>
                    {blurred && (
                        <div className="upgrade-button-container">
                            <a href="/billing" className="upgrade-button" style={{ textDecoration: 'none' }}>
                                Upgrade to {planData.plan}
                            </a>
                        </div>
                    )}
                    <p className="card-description">{planData.description}</p>
                </div>
            );
        });
    };

    const handleToggleFullPageInsights = () => {
        if (!isBlurred('Pro Settings')) {
            setFullPageInsightsToggle(!fullPageInsights);
        }
    };
    

    return (
        <div className="settings-container">
            <div className="settings-header">
            <h2 className="settings-title">Settings</h2>
            </div>
            {isLoading ? renderLoadingState() : (
            <div className="cards-grid">
                <div className="top-row">{renderCardsAndButtons().slice(0, 2)}</div>
                <div className="bottom-row">{renderCardsAndButtons().slice(2, 4)}</div>
            </div>
            )}
        </div>
    );
};

export default Settings;
