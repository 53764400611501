/* global google */
/* global gapi */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, GoogleAuthProvider, signInWithPopup, getRedirectResult, signInWithRedirect, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore


// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDi6fOQqQfalZghWTrW3rJ9ku6Tw9m9GYo",
    authDomain: "quickinsights-284de.firebaseapp.com",
    projectId: "quickinsights-284de",
    storageBucket: "quickinsights-284de.firebasestorage.app",
    messagingSenderId: "1038698941000",
    appId: "1:1038698941000:web:bbecb7b0cd5f193ed1667d",
    measurementId: "G-PZTTG6V6Z7"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore


const CLIENT_ID = "1038698941000-g2qlach0atplvgn6feqe55es06q9eq6j.apps.googleusercontent.com";
const API_KEY = "AIzaSyANKmnIH37WiL8a5CIsQUQU0pB5er9fdkk";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = [
    'https://www.googleapis.com/auth/drive.file', // For creating and accessing user-specific files
    'https://www.googleapis.com/auth/drive',     // For broader access, such as reading/writing to any file
  ];



const UserContext = createContext();


// Export the auth instance to use in other parts of your app
export { auth, db, isSignInWithEmailLink, signInWithEmailLink, GoogleAuthProvider, signInWithPopup, getRedirectResult, signInWithRedirect, setPersistence, browserLocalPersistence };

const provider = new GoogleAuthProvider();

export const useUser = () => {
    return useContext(UserContext); // Hook to access user context
};

// Helper function to get the user's plan

export const getUserPlan = (user) => {
    return user ? user.plan : null;  
};

export const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
        email: params.get('email'),
        plan: params.get('plan'),
    };
};


export const UserProvider = ({ children }) => {
    const defaultPlan = 'Free'; // Default plan for testing
    const [user, setUser] = useState(null); // State to store user data
    const [userPlan, setUserPlan] = useState(() => {
        return localStorage.getItem('userPlan');
    });
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [tokenClient, setTokenClient] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [totalRequests, setTotalRequests] = useState(0);

    // Function to update total requests
    const updateTotalRequests = (newActivityLog) => {
        setTotalRequests(newActivityLog.length);
    };
    useEffect(() => {
        if (userPlan) {
            localStorage.setItem('userPlan', userPlan);
        }
    }, [userPlan]);

    
    useEffect(() => {
        const loadGoogleAPI = () => {
            return new Promise((resolve, reject) => {
                if (window.google && google.accounts) {
                    resolve();
                } else {
                    const interval = setInterval(() => {
                        if (window.google && google.accounts) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 100);
                    setTimeout(() => {
                        clearInterval(interval);
                        reject(new Error("Google API failed to load."));
                    }, 10000); // Timeout after 10 seconds
                }
            });
        };
    
        const initOAuthClient = async () => {
            try {
                await loadGoogleAPI();
                const tokenClient = google.accounts.oauth2.initTokenClient({
                    client_id: CLIENT_ID,
                    scope: SCOPES.join(" "),
                    callback: async (tokenResponse) => {
                        if (tokenResponse.access_token) {
                            localStorage.setItem("access_token", tokenResponse.access_token);
                            setIsSignedIn(true);
                            await fetchUserData(tokenResponse.access_token);
                        } else {
                            handleSignOut();
                        }
                    },
                });
                setTokenClient(tokenClient);
            } catch (error) {
                console.error("Error initializing Google OAuth Client:", error);
                setError(error.message);
            }
        };
    
        const token = localStorage.getItem("access_token");
        if (token) {
            setIsSignedIn(true);
            fetchUserData(token);
        } else {
            initOAuthClient();
        }
    }, []);

    
    useEffect(() => {
        const loadScripts = async () => {
            await loadGis();
            await loadGapi();
        };

        const loadGis = () => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://accounts.google.com/gsi/client";
                script.onload = () => {
                    initGis();
                    resolve();
                };
                document.body.appendChild(script);
            });
        };

        const loadGapi = () => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = "https://apis.google.com/js/api.js";
                script.onload = () => {
                    initGapi();
                    resolve();
                };
                document.body.appendChild(script);
            });
        };

        const initGis = () => {
            const tokenClientInstance = google.accounts.oauth2.initTokenClient({
                client_id: CLIENT_ID,
                scope: SCOPES.join(" "),
                callback: async (tokenResponse) => {
                    if (tokenResponse.access_token) {
                        localStorage.setItem("access_token", tokenResponse.access_token);
                        setIsSignedIn(true);
                        await fetchUserData(tokenResponse.access_token);
                    } else {
                        setIsSignedIn(false);
                    }
                },
            });
            setTokenClient(tokenClientInstance);
            const token = localStorage.getItem("access_token");
            if (token) {
                setIsSignedIn(true);
                fetchUserData(token);
            }
        };

        const initGapi = () => {
            gapi.load("client:auth2", () => {
                gapi.client.init({
                    apiKey: API_KEY,
                    discoveryDocs: DISCOVERY_DOCS,
                }).then(() => {
                  //  console.log("GAPI client initialized successfully");
                  // console.log(gapi.client); // Check the state of gapi.client here
                }).catch((error) => {
                    console.error("Error initializing GAPI client:", error);
                });
            });
        };


        

        loadScripts();
    }, []);



    const setUserState = (data) => {
        setUser(data.user);
        setIsSignedIn(true);
        fetchUserPlanFromFirestore(data.user.email);
    };
    
    const handleSignOut = () => {
        setUser(null); // Clear user data on sign out
        setIsSignedIn(false);
        localStorage.removeItem('userPlan', userPlan); // Clear stored user plan
        localStorage.removeItem('accessToken'); 
        sessionStorage.removeItem('access_token'); 
    };


// Function to fetch user data using access token
const fetchUserData = async (accessToken) => {
    try {
        const response = await fetch('https://server.quickinsights-ai.com/api/auth/user', {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        const data = await response.json();
        if (data.success) {
            setUserState(data); 

        } else {
            handleSignOut();  // Centralize sign-out handling
        }
    } catch (error) {
        handleSignOut(); // Centralize sign-out handling
    }
};

const processMagicLink = () => {
    const emailLink = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const savedEmail = params.get('email');
    const expiryTimestamp = params.get('expiry');
    const authUrl = params.get('authUrl');

    // Check if the URL contains the magic link parameters (email, expiry, authUrl)
    if (!savedEmail || !expiryTimestamp || !authUrl) {
        //console.log('No magic link found in the URL, skipping magic link processing.');
        return; // Exit early, don't process magic link
    }

    const currentTime = Date.now();
    console.log('Current time:', currentTime);
    console.log('Saved email:', savedEmail);
    console.log('Expiry timestamp:', expiryTimestamp);
    console.log('Auth URL:', authUrl);

    // Check if the magic link has already been processed
    const isProcessed = sessionStorage.getItem('magicLinkProcessed');
    if (isProcessed === 'true') {
        console.log('Magic link already processed, avoiding duplicate execution.');
        setIsLoading(false); // Update loading state
        return;
    }

    // Check for expired or invalid link
    if (currentTime > Number(expiryTimestamp)) {
        console.error('The magic link has expired.');
        sessionStorage.setItem('magicLinkProcessed', 'true'); // Mark as processed to prevent repeat alerts
        alert('The magic link has expired. You will be redirected to the login page.');
        setTimeout(() => {
            console.log('Redirecting to /login...');
            window.location.href = '/login'; // Redirect to login page
        }, 1000);
        setIsLoading(false); // Update loading state
        return;
    }

    // Process valid magic link
    if (isSignInWithEmailLink(auth, emailLink)) {
        console.log('Valid magic link received:', emailLink);
        localStorage.setItem('emailForSignIn', savedEmail);
        sessionStorage.setItem('magicLinkProcessed', 'true');

        if (authUrl) {
            console.log('Redirecting to OAuth consent screen...');
            setTimeout(() => {
                console.log('Redirecting to auth URL:', authUrl);
                window.location.href = authUrl; // Redirect to OAuth URL
            }, 500);
            return; // Early return to prevent further execution
        }
    } else if (authUrl) {
        console.log('Redirecting to OAuth consent screen...');
        sessionStorage.setItem('magicLinkProcessed', 'true');

        setTimeout(() => {
            console.log('Redirecting to auth URL:', authUrl);
            window.location.href = authUrl; // Redirect to OAuth URL
        }, 500);
        return; // Early return to prevent further execution
    } else {
        console.error('No valid sign-in link or auth URL found.');
        setError('No valid link found.');
    }

    setIsLoading(false); // Update loading state if no redirects happen
};

// Call the processing function on mount
useEffect(() => {
    processMagicLink(); 
}, []); // Empty dependency array to ensure it runs once




// Fetch user data when component mounts
useEffect(() => {
    setIsLoading(true);
    signIn().finally(() => setIsLoading(false));
}, []);






// Unified sign-in function to handle OAuth
const signIn = async () => {
    try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            await fetchUserData(accessToken); // Fetch user data if accessToken exists
            setIsSignedIn(true); // User is signed in if data is retrieved successfully
            return true; // Return true to indicate success
        } else {
            console.log('No access token found. User needs to sign in.');
            setIsSignedIn(false); // User needs to sign in
            setUserPlan(defaultPlan);
            return false; // Return false to indicate failure
        }
    } catch (error) {
        console.error('Sign-in error:', error);
        setIsSignedIn(false); // Update state on error
        return false; // Return false to indicate failure
    }
};




const fetchUserPlanFromFirestore = async (email) => {
    try {
        const userDocRef = doc(db, "users", email);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            setUserPlan(userData.plan || 'Free');
        } else {
            console.log("No document found, setting default plan.");
            setUserPlan('Free');
        }
    } catch (err) {
        console.error("Error fetching user plan:", err);
    }
};




const saveUserPlanToFirestore = async (
    email,
    plan,
    tokensUsed = 0,
    insightsTokensUsed = 0,
    db = getFirestore(),
    invitedCount = 0
) => {
    if (!email) {
        console.error("Email is required to save the plan.");
        return;
    }

    // Function to generate a referral code if not already available
    const generateReferralCode = () =>
        `QI-${Math.random().toString(36).substr(2, 8).toUpperCase()}`;

    try {
        // Reference to the Firestore document for the user
        const userDocRef = doc(db, "users", email);
        const userDocSnapshot = await getDoc(userDocRef);

        // Retrieve existing data or initialize defaults
        const existingData = userDocSnapshot.exists()
            ? userDocSnapshot.data()
            : {
                  tokensUsed: 0,
                  insightsTokensUsed: 0,
                  invitedCount: 0,
              };

        // Generate or reuse a referral code
        const referralCode =
            existingData.referralCode || generateReferralCode();

        // Add incoming data to existing data
        const updatedTokensUsed = existingData.tokensUsed + tokensUsed;
        const updatedInsightsTokensUsed =
            existingData.insightsTokensUsed + insightsTokensUsed;
        const updatedInvitedCount = existingData.invitedCount + invitedCount;

        // Construct updated data object
        const updatedUserData = {
            ...existingData, // Ensure existing data is preserved
            plan, // Update the plan
            referralCode, // Ensure referral code is preserved or generated
            tokensUsed: updatedTokensUsed,
            insightsTokensUsed: updatedInsightsTokensUsed,
            invitedCount: updatedInvitedCount,
        };

        // Save the updated data to Firestore
        await setDoc(userDocRef, updatedUserData, { merge: true });

        console.log("User plan successfully saved to Firestore:", updatedUserData);
    } catch (error) {
        console.error("Error saving user data to Firestore:", error);
    }
};




useEffect(() => {
    if (user && user.email) {
        const params = getQueryParams();
        if (params.plan) {
            setUserPlan(params.plan);
            saveUserPlanToFirestore(user.email, params.plan, db);
        } else {
            fetchUserPlanFromFirestore(user.email); // Fetch based on user email
        }
    }
}, [user, db]);



// Updated function to change user plan by email
const changePlanByEmail = async (email, userPlan) => {
    if (user && user.email === email) {
        setUserPlan(userPlan);
        await saveUserPlanToFirestore(userPlan); // Save to Google Drive if needed
        console.log(`User plan for ${email} changed to ${userPlan}`);
    } else {
       // console.warn(`User with email ${email} is not signed in or does not match.`);
    }
};
useEffect(() => {
    changePlanByEmail(); 
}, []); 


        // Optional: You can call saveUserPlanToDrive when you want to save the plan
        const handleSavePlan = () => {
            saveUserPlanToFirestore(userPlan); // Pass the current user plan to save
        };




    // Function to handle user logout
    const logout = () => {
        if (isSignedIn && window.gapi) {
            gapi.auth2.getAuthInstance().signOut(); // Sign out from Google
        }
        setUser(null); // Clear user data
        localStorage.removeItem('user'); // Clear user from local storage
        localStorage.removeItem('accessToken'); // Clear access token
        localStorage.removeItem('userPlan', userPlan);      
    };
    




    return (
        <UserContext.Provider value={{ user, isLoading, error, setUser, signIn, saveUserPlanToFirestore, isSignedIn, userPlan, updateTotalRequests, changePlanByEmail, getUserPlan, getQueryParams, logout }}>
            {children}
        </UserContext.Provider>
    );
  
};

export default UserContext;

/*
// Function to save user data (plan, referral code, and invited count) to Google Drive
const saveUserDataToDrive = async (plan, invitedCount = 0) => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
        console.error('No access token found.');
        return;
    }

    if (!user || !user.email) {
        console.error('User not authenticated or email missing.');
        return;
    }
    const email = user.email;

    const generateReferralCode = () => `QI-${Math.random().toString(36).substr(2, 8).toUpperCase()}`;

    try {
        // Step 1: Check if the file exists
        const listResponse = await fetch(
            "https://www.googleapis.com/drive/v3/files?q=name='user_plan.json'&fields=files(id)",
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        const files = await listResponse.json();
        let fileId = null;
        let existingData = {};

        if (files.files.length > 0) {
            fileId = files.files[0].id;

            // Step 2: Fetch existing data from the file
            const getFileResponse = await fetch(
                `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (getFileResponse.ok) {
                existingData = await getFileResponse.json();
            }
        }

        // Step 3: Ensure referral code is set only once
        const referralCode = existingData.referralCode || generateReferralCode();

        // Step 4: Update invited count if necessary
        const updatedInvitedCount = existingData.invitedCount || 0;
        const newInvitedCount = updatedInvitedCount + invitedCount;

        // Step 5: Prepare updated content
        const updatedContent = {
            ...existingData,
            plan,
            referralCode,
            invitedCount: newInvitedCount,  // Add the total invited count to the data
        };

        // Step 6: Save the updated data back to the file
        const media = {
            mimeType: 'application/json',
            body: JSON.stringify(updatedContent),
        };

        if (fileId) {
            // Update existing file
            await fetch(`https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=media`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: media.body,
            });
        } else {
            // Create a new file if it doesn't exist
            const fileMetadata = {
                name: 'user_plan.json',
                mimeType: 'application/json',
            };

            const body = new FormData();
            body.append(
                'metadata',
                new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' })
            );
            body.append(
                'media',
                new Blob([media.body], { type: 'application/json' })
            );

            await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: body,
            });
        }
    } catch (error) {
        console.error('Error saving user data to Google Drive:', error);
    }
};
*/

/*
// Fetch the user's plan from Google Drive
const fetchUserPlanFromDrive = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
        console.error('No access token available.');
        return;
    }
    

    try {
        const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name='user_plan.json'&fields=files(id)`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        const data = await response.json();
        if (data.files && data.files.length > 0) {
            const fileId = data.files[0].id;

            // Fetch the content of the file
            const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (!fileResponse.ok) {
                throw new Error('Failed to fetch user plan data');
            }

            const userData = await fileResponse.json();

            // Check if the plan exists and update the user plan
            if (userData.plan) {
                setUserPlan(userData.plan); // Update state with fetched plan
                localStorage.setItem('userPlan', userData.plan); // Persist to localStorage
            } else {
                console.log('No plan found in user data. Setting default plan.');
                setUserPlan(defaultPlan); // Fallback to default plan
            }
        } else {
            console.log('No plan file found in Google Drive. Setting default plan.');
            setUserPlan(defaultPlan); // Fallback to default plan if no file found
        }
    } catch (error) {
        console.error('Error fetching user plan from Google Drive:', error);
        setUserPlan(defaultPlan); // In case of any errors, set default plan
    }
};
*/

/*
useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);

        const signedIn = await signIn(); // Sign-in logic
        if (signedIn) {
            // Only fetch the plan if the user is signed in
            await fetchUserPlanFromFirestore(defaultPlan, db);
        } else {
            setUserPlan(defaultPlan); // Assign default plan if not signed in
        }

        setIsLoading(false);
    };

    fetchData();
}, []); // This effect runs once on component mount
*/


/*
    useEffect(() => {
        const { email, plan } = getQueryParams();
    
        if (email && plan) {
            // Dynamic assignment after payment success
            changePlanByEmail(email, plan);
        }
        else if (user && user.email === "leemxb@gmail.com") {
            // Development-specific fallback
            changePlanByEmail(user.email, "Pro"); // Force set plan for specific email
        } else if (user && !isLoading) {
            // Fallback to fetch user plan from Drive
            fetchUserPlanFromDrive();
        }
    }, []);
    */

    


