import React, { useEffect, useState } from 'react';
import './styles/dashboard.css';
import { useUser, getUserPlan } from '../context/UserContext';
import UsageStatistics from './UsageStatistics';
import APITokenLimit from './APITokenLimit';
import RecentActivity from './RecentActivity';

const Dashboard = () => {
    const { user, isSignedIn, loading, error } = useUser();
    const [isLoading, setIsLoading] = useState(true); // Initially loading
    const [userPlan, setUserPlan] = useState(null); // Store user's plan

    useEffect(() => {
        if (!loading) { // Wait until loading state from useUser is false
            if (isSignedIn && user) {
                const accessToken = localStorage.getItem('accessToken');
                const fetchedUserPlan = getUserPlan(user); 
                setUserPlan(fetchedUserPlan); // Set the user's plan in state
                if (accessToken) {
                    window.postMessage({ action: 'authSuccess', accessToken, userPlan: fetchedUserPlan }, '*'); // Notify the extension
                }
                setIsLoading(false); // Data loaded, stop the spinner
            } else {
                setIsLoading(false); // Stop the spinner even if not signed in
            }
        }
    }, [user, isSignedIn, loading]); // Dependencies for useEffect

    const renderLoadingState = () => (
        <div className="loading-container">
            <div className="loading-spinner"></div>
        </div>
    );

    return (
        <>
            {isLoading ? (
                renderLoadingState()
            ) : (
                <main className="dashboard">
                    <header className="dashboard-header">
                        <h2>
                            {/* Check if user is available and has a name before rendering */}
                            Hello, {user ? (user.given_name || user.name.split(' ')[0] || 'Guest') : 'Guest'}
                            <span className="emoji"> 👋</span>
                        </h2>
                        {userPlan && (
                            <div className="user-plan">
                                <span>Plan: {userPlan}</span> {/* Display the plan */}
                            </div>
                        )}
                    </header>
                    <section className="dashboard-content">
                        <div className="dashboard-grid">
                            <UsageStatistics user={user} />
                            <APITokenLimit user={user} />
                            <RecentActivity user={user} />
                        </div>
                    </section>
                </main>
            )}
        </>
    );
};

export default Dashboard;
