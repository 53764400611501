import React, { useState, useEffect } from 'react';
import './requests.css';
import { useUser } from '../../context/UserContext';
import { useLocation } from 'react-router-dom';

const ActivityLog = () => {
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [expandedSections, setExpandedSections] = useState({ Today: true });
    const { user, isSignedIn, signIn, updateTotalRequests } = useUser();
    const [loading, setLoading] = useState(false);
    const [activityLog, setActivityLog] = useState([]);
    const [fileId, setFileId] = useState(null);
    const [newActivityRequest, setNewActivityRequest] = useState(null);
    const [hoveredRequestId, setHoveredRequestId] = useState(null);
    const [selectedTab, setSelectedTab] = useState('selectedText');
    const { search } = useLocation();
    // Parse query parameters to get the request ID
    const urlParams = new URLSearchParams(search);
    const requestId = urlParams.get('id');


    useEffect(() => {
        // If there's a requestId in the URL, find the matching request in the activity log
        if (requestId && activityLog.length > 0) {
            const foundRequest = activityLog.find(req => req.id === requestId);
            if (foundRequest) {
                setSelectedRequest(foundRequest);
            }
        }
    }, [requestId, activityLog]);


    useEffect(() => {
        if (newActivityRequest && isSignedIn && user) {
            const currentDateTime = new Date().toISOString(); // Full date and time
        
            const requestWithDate = { ...newActivityRequest, date: currentDateTime }; // Include full timestamp

            // Same logic for handling duplicates and updating the log
            const updatedLog = [...activityLog, requestWithDate].filter(log => {
                const logDate = new Date(log.date);
                const diffInDays = (new Date() - logDate) / (1000 * 3600 * 24);
                return diffInDays <= 30;
            }).sort((a, b) => new Date(b.date) - new Date(a.date));
            
            setActivityLog(updatedLog);

            // Update total requests in the UserContext
            updateTotalRequests(updatedLog);

            setNewActivityRequest(null);
        }
    }, [newActivityRequest, isSignedIn, user, activityLog]);

    useEffect(() => {
        const socket = new WebSocket('wss://server.quickinsights-ai.com'); // Replace with your server URL
    
        socket.onopen = () => console.log('WebSocket connection established');
        socket.onmessage = (event) => {
            try {
                const messageData = JSON.parse(event.data);
                console.log('Message from WebSocket:', messageData);
    
                if (messageData.action === 'updateActivityLog') {
                    addNewActivity(messageData.data);
                }
            } catch (err) {
                console.error('Error parsing WebSocket message:', err);
            }
        };
    
        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    
        socket.onclose = (event) => {
            console.warn('WebSocket connection closed:', event.reason);
            // Optionally, attempt to reconnect after a delay
            setTimeout(() => {
                console.log('Reconnecting WebSocket...');
                // Call the effect again to establish a new connection
            }, 5000);
        };
    
        return () => socket.close(); // Cleanup
    }, []);
    


    // Function to add new activity to the log
    const addNewActivity = (newRequest) => {
        console.log('New activity received:', newRequest);
        const uniqueRequestId = new Date().toISOString();
        const requestWithId = {
            ...newRequest,
            id: uniqueRequestId,
            type: newRequest.type || 'selectedText',
            categoryInitials: newRequest.category === 'selectedTextUploads' ? 'STU' :
                            newRequest.category === 'searchInsights' ? 'SI' :
                            newRequest.category === 'selectedText' ? 'ST' :
                            'PI'
        };
        setNewActivityRequest(requestWithId);
    };



    


    useEffect(() => {
        // Check user sign-in state and load activity log from Google Drive
        if (isSignedIn && user) {
            loadActivityLogFromGoogleDrive();
        }
    }, [user, isSignedIn]);

    
    // Effect to process new activity requests
    useEffect(() => {
        if (newActivityRequest && isSignedIn && user) {
            const currentDateTime = new Date().toISOString(); // Full date and time
    
            const requestWithDate = { ...newActivityRequest, date: currentDateTime }; // Include full timestamp
    
            // Check for duplicates
            const isDuplicate = activityLog.some(log => 
                log.selectedText === requestWithDate.selectedText &&
                log.userPrompt === requestWithDate.userPrompt &&
                log.response === requestWithDate.response
            );
    
            if (isDuplicate) {
                console.log('Duplicate activity detected, not adding to log');
                setNewActivityRequest(null);
                return;
            }
    
            // Update activity log and sort by date (latest first)
            const updatedLog = [...activityLog, requestWithDate]
                .filter(log => {
                    const logDate = new Date(log.date);
                    const diffInDays = (new Date() - logDate) / (1000 * 3600 * 24);
                    return diffInDays <= 30; // Keep only activity within the last 30 days
                })
                .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date (latest first)
    
            console.log('Updated Activity Log:', updatedLog); // Debugging log
            setActivityLog(updatedLog);
            setNewActivityRequest(null);
        }
    }, [newActivityRequest, isSignedIn, user, activityLog]);


    
    const updateActivityLogInGoogleDrive = async (updatedLog) => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            signIn();
            return;
        }
    
        if (!user || !user.id) {
            console.error('User is not signed in or user ID is not available');
            return;
        }
    
        // Ensure requests is an object, not an array
        const logData = { requests: {} };
        updatedLog.forEach(entry => {
            const date = entry.date.split('T')[0];  // Extract date from datetime
            if (!logData.requests[date]) {
                logData.requests[date] = [];
            }
            logData.requests[date].push(entry);
        });
    
        try {
            const fileName = `${user.id}_requests.json`;
            let response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}" and trashed=false`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
            let fileId;
    
            if (data.files && data.files.length > 0) {
                fileId = data.files[0].id;
    
                // Update the existing log by sending the updated requests structure
                response = await fetch(`https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=media`, {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(logData),
                });
            } else {
                console.error('Log file not found in Google Drive, cannot delete request');
                return; // No file found, so no action
            }
    
            const result = await response.json();
            if (!response.ok) {
                console.error('Error updating activity log in Google Drive:', result);
                return;
            }
    
            if (result?.id) {
                setFileId(result.id);
            } else {
                console.warn('No file ID returned from Google Drive API:', result);
            }
        } catch (error) {
            console.error('Error updating activity log in Google Drive:', error);
        }
    };
    
    
    
    const handleDeleteRequest = async (requestToDelete) => {
        if (!requestToDelete || !requestToDelete.id) {
            console.error('Invalid request to delete:', requestToDelete);
            return;
        }
        try {
            const requestElement = document.querySelector(`[data-id='${requestToDelete.id}']`);
            if (requestElement) {
                requestElement.classList.add('deleting'); // Add a class for animation
            } else {
                console.error(`Element not found for request ID: ${requestToDelete.id}`);
                return; // Exit if the element is not found
            }
    
            // Wait for the animation to complete before updating the log
            setTimeout(async () => {
                // Update the activity log state by filtering out the deleted request
                const updatedLog = Array.isArray(activityLog)
                    ? activityLog.filter(request => request.id !== requestToDelete.id)
                    : []; // Ensure updatedLog is always an array
    
                setActivityLog(updatedLog); // Update the state with the modified log
    
                // Update the Google Drive log by removing the deleted request
                await updateActivityLogInGoogleDrive(updatedLog);
            }, 300); // Adjust the timeout based on your animation duration
        } catch (error) {
            console.error("Error deleting request:", error);
        }
    };
    


    
    
    
    
    
    
    
        
        // Helper function to truncate text
        const truncateText = (text, maxLength = 20) => {
            if (text.length <= maxLength) return text; // Return the original text if it's short enough
            return text.slice(0, maxLength) + '...'; // Return truncated text with ellipses
        };
    
        // Add state to manage the number of requests displayed
        const [requestsToShow, setRequestsToShow] = useState({
            Today: 10,
            Yesterday: 10,
            'Previous 7 Days': 10,
            'Previous 30 Days': 10
        });
    
        // Function to load more requests
        const loadMoreRequests = (period) => {
            setRequestsToShow((prev) => ({
                ...prev,
                [period]: prev[period] + 10 // Increase by 10 more requests
            }));
        };
    
      
    

    // Function to toggle the expansion state of sections
    const toggleSection = (period) => {
        setExpandedSections((prev) => ({
            ...prev,
            [period]: !prev[period],
        }));
        
        // Reset selected request when the section is toggled
        if (expandedSections[period]) {
            setSelectedRequest(null); // Collapse section, clear selected request
        }
    };


    const loadActivityLogFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            signIn();
            return;
        }
    
        setLoading(true);
        try {
            const fileName = `${user.id}_requests.json`;
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
            if (data.files && data.files.length > 0) {
                const fileId = data.files[0]?.id;
                setFileId(fileId);
    
                const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
    
                if (!fileResponse.ok) {
                    throw new Error('Failed to fetch activity log data');
                }
    
                const logData = await fileResponse.json();
    
                // Ensure requests is an object and handle the data
                if (logData.requests && Object.keys(logData.requests).length > 0) {
                    const flattenedRequests = Object.entries(logData.requests).flatMap(([date, entries]) =>
                        entries.map(entry => ({
                            date,
                            categoryInitials: entry.category === 'selectedTextUploads' ? 'STU' : 
                                              entry.category === 'selectedText' ? 'ST' : 
                                              entry.category === 'pageInsights' ? 'PI' : 
                                              entry.category === 'searchInsights' ? 'SI' : '',
                            ...entry
                        }))
                    );
    
                    setActivityLog(flattenedRequests);
                } else {
                    console.warn('No requests found in the activity log.');
                    setActivityLog([]); // Set to an empty array if no requests
                }
            } else {
                console.warn('No activity log found, creating a new one');
                setActivityLog([]); // Set to an empty array if no file found
                setFileId(null);
            }
        } catch (error) {
            console.error('Error loading activity log from Google Drive:', error);
        } finally {
            setLoading(false);
        }
    };
    
    
    
    
    
    // Function to group requests by time period
    const groupRequests = (period) => {
    const now = new Date();
    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // Midnight today
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfYesterday.getDate() - 1); // Midnight yesterday

    const startOf7DaysAgo = new Date(startOfToday);
    startOf7DaysAgo.setDate(startOfToday.getDate() - 7); // 7 days ago at midnight

    const startOf30DaysAgo = new Date(startOfToday);
    startOf30DaysAgo.setDate(startOfToday.getDate() - 30); // 30 days ago at midnight

    // Ensure activityLog is an array
    if (!Array.isArray(activityLog)) {
        // console.warn('activityLog is not an array:', activityLog);
        return []; // Return an empty array if activityLog is not an array
    }

    return activityLog.filter((request) => {
        const requestDate = new Date(request.date); // Convert the request date string to Date object

        switch (period) {
            case 'Today':
                return requestDate >= startOfToday; // Include only requests from today
            case 'Yesterday':
                return requestDate >= startOfYesterday && requestDate < startOfToday; // Include only requests from yesterday
            case 'Previous 7 Days':
                return requestDate >= startOf7DaysAgo && requestDate < startOfYesterday; // Requests between 2 and 7 days ago
            case 'Previous 30 Days':
                return requestDate >= startOf30DaysAgo && requestDate < startOf7DaysAgo; // Requests between 8 and 30 days ago
            default:
                return false;
        }
    }).sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date, latest first
    };

    
    


    const handleRequestClick = (request) => {
        setLoading(true);
        setSelectedRequest(null);
    
        setTimeout(() => {
            setSelectedRequest(request);
            if (request.categoryInitials === 'STU') {
                setSelectedTab('fileUploads');
            } else if (request.categoryInitials === 'ST') {
                setSelectedTab('selectedText');
            } else if (request.categoryInitials === 'PI') {
                setSelectedTab('pageContent');
            } else if (request.categoryInitials === 'SI') {
                setSelectedTab('searchedText');
            }
            setLoading(false);
        }, 500);
    };
    




    return (
        <div className="activity-log">
            <div className="requests-header">
            <h2>Requests Made</h2>
            </div>
            <div className="log-container">
                {/* Sidebar for Time Periods */}
                <div className="time-period-column">
                    <h3>Time Period</h3>
                    <ul>
                        {['Today', 'Yesterday', 'Previous 7 Days', 'Previous 30 Days'].map((period) => {
                            const requestsInPeriod = groupRequests(period);
                            const isExpanded = expandedSections[period];
                            const visibleRequests = requestsInPeriod.slice(0, requestsToShow[period]);
    
                            return (
                                <li key={period} className={`time-period ${isExpanded ? 'open' : ''}`}>
                                    <strong onClick={() => toggleSection(period)} style={{ cursor: 'pointer' }}>
                                        {period} ({requestsInPeriod.length} requests)
                                    </strong>
                                    <ul className={`nested-requests ${isExpanded ? 'show' : ''}`}>
                                        {isExpanded && visibleRequests.map((request, index) => {
                                            const requestId = request.id || index;
    
                                            return (
                                                <div className="request-container" key={requestId}>
                                                {/* Initials displayed outside the main request-item container */}
                                                <span
                                                    className="initials"
                                                    style={{
                                                        color: request.categoryInitials === 'ST' ? 'blue' 
                                                            : request.categoryInitials === 'PI' ? 'orange' 
                                                            : request.categoryInitials === 'SI' ? 'purple' 
                                                            : request.categoryInitials === 'STU' ? 'cyan' // Unique color for STU
                                                            : 'black' // Default color if none matches
                                                    }}
                                                >
                                                    [{request.categoryInitials}]
                                                </span>

                        
                                                {/* Main request item */}
                                                <li
                                                    onClick={() => handleRequestClick(request)}
                                                    className={`request-item ${selectedRequest?.id === request.id ? 'selected' : ''}`}
                                                    data-id={requestId}
                                                    onMouseEnter={() => setHoveredRequestId(requestId)}
                                                    onMouseLeave={() => setHoveredRequestId(null)}
                                                >
                                                    {truncateText(request.selectedText)}
                                                    {hoveredRequestId === requestId && (
                                                        <button
                                                            className="delete-button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteRequest(request);
                                                            }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-trash3" viewBox="0 0 16 16">
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                                                            </svg>
                                                        </button>
                                                    )}
                                                </li>
                                            </div>
                                            );
                                        })}
                                        {/* Show "Load More" button if there are more than shown requests */}
                                        {isExpanded && requestsInPeriod.length > requestsToShow[period] && (
                                            <button onClick={() => loadMoreRequests(period)}>
                                                Load More
                                            </button>
                                        )}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                    <span className="wavy-arrow" />
                </div>
                <div className="scrollable-wrapper">
                    {/* Vertical Tabs Section */}
                    <div className="scrollable-tabs">
                        <div className="vertical-tabs">
                            {/* Show tabs for Selected Text category */}
                            {selectedRequest?.categoryInitials === 'ST' && (
                                <>
                                    <button
                                        onClick={() => setSelectedTab('selectedText')}
                                        className={selectedTab === 'selectedText' ? 'active-tab' : ''}
                                    >
                                        Selected Text
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('userPrompt')}
                                        className={selectedTab === 'userPrompt' ? 'active-tab' : ''}
                                    >
                                        User Prompt
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('response')}
                                        className={selectedTab === 'response' ? 'active-tab' : ''}
                                    >
                                        Text Insights
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('videoLinks')}
                                        className={selectedTab === 'videoLinks' ? 'active-tab' : ''}
                                    >
                                        Media Insights
                                    </button>
                                </>
                            )}

                            {/* Show tabs for Selected Text Uploads category */}
                                {selectedRequest?.categoryInitials === 'STU' && (
                                    <>
                                       <button
                                            onClick={() => setSelectedTab('selectedText')}
                                            className={selectedTab === 'selectedText' ? 'active-tab' : ''}
                                        >
                                            Selected Text
                                        </button>
                                        <button
                                            onClick={() => setSelectedTab('fileUploads')}
                                            className={selectedTab === 'fileUploads' ? 'active-tab' : ''}
                                        >
                                            File Uploaded
                                        </button>
                                        <button
                                            onClick={() => setSelectedTab('userPrompt')}
                                            className={selectedTab === 'userPrompt' ? 'active-tab' : ''}
                                        >
                                            User Prompt
                                        </button>
                                        <button
                                            onClick={() => setSelectedTab('response')}
                                            className={selectedTab === 'response' ? 'active-tab' : ''}
                                        >
                                            Upload Insights
                                        </button>

                                        <button
                                            onClick={() => setSelectedTab('videoLinks')}
                                            className={selectedTab === 'videoLinks' ? 'active-tab' : ''}
                                        >
                                            Media Insights
                                        </button>
                                    </>
                                )}


                            {/* Show tabs for Page Insights category */}
                            {selectedRequest?.categoryInitials === 'PI' && (
                                <>
                                    <button
                                        onClick={() => setSelectedTab('pageContent')}
                                        className={selectedTab === 'pageContent' ? 'active-tab' : ''}
                                    >
                                        Page Content
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('source')}
                                        className={selectedTab === 'source' ? 'active-tab' : ''}
                                    >
                                        Source Link
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('pageInsights')}
                                        className={selectedTab === 'pageInsights' ? 'active-tab' : ''}
                                    >
                                        Page Insights
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('videoLinks')}
                                        className={selectedTab === 'videoLinks' ? 'active-tab' : ''}
                                    >
                                        Media Insights
                                    </button>
                                </>
                            )}

                            {selectedRequest?.categoryInitials === 'SI' && (
                                <>
                                    <button
                                        onClick={() => setSelectedTab('searchedText')}
                                        className={selectedTab === 'searchedText' ? 'active-tab' : ''}
                                    >
                                        Searched Text
                                    </button>
                                    <button
                                        onClick={() => setSelectedTab('searchInsights')}
                                        className={selectedTab === 'searchInsights' ? 'active-tab' : ''}
                                    >
                                        Search Insights
                                    </button>
                                </>
                            )}


                        </div>

                    </div>
                </div>

    
                    {/* Tab Content Area */}
                    <div className="tab-content">
                        {loading ? (
                            <div className="loading-spinner"></div>
                        ) : selectedRequest ? (
                            selectedTab === 'response' ? (
                                Array.isArray(selectedRequest.response) ? (
                                    // If multiple responses exist, display them with labels
                                    selectedRequest.response.map((res, index) => (
                                        <div key={index}>
                                            <p><strong>QI Response {index + 1}:</strong></p>
                                            <div dangerouslySetInnerHTML={{ __html: res }} />
                                        </div>
                                    ))
                                ) : (
                                    // Single response
                                    <div>
                                        <p><strong>QI Response:</strong></p>
                                        <div dangerouslySetInnerHTML={{ __html: selectedRequest.response }} />
                                    </div>
                                )
                            ) : selectedTab === 'selectedText' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.selectedText }} />
                            ) : selectedTab === 'fileUploads' ? (
                                <div>
                                    <p><strong>File Name:</strong> {selectedRequest.fileAttachment.fileName}</p>
                                    <p><strong>File Size:</strong> {selectedRequest.fileAttachment.fileSize}</p>
                                    <p><strong>File Type:</strong> {selectedRequest.fileAttachment.fileType}</p>
                                </div>
                            ) : selectedTab === 'userPrompt' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.userPrompt }} />
                            ) : selectedTab === 'videoLinks' ? (
                                // Display video links as a list
                                Array.isArray(selectedRequest.videoLinks) && selectedRequest.videoLinks.length > 0 ? (
                                    <div>
                                        <p><strong>Media Insights:</strong></p>
                                        <ul>
                                            {selectedRequest.videoLinks.map((video, index) => (
                                                <li key={index}>
                                                    <a href={video.url} target="_blank" rel="noopener noreferrer">
                                                        {video.title}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <p>No media insights available.</p>
                                )
                            ) : selectedTab === 'pageContent' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.selectedText }} />
                            ) : selectedTab === 'source' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.sourceURL }} />
                            ) : selectedTab === 'pageInsights' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.response }} />
                            ) : selectedTab === 'searchedText' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.selectedText }} />
                            ) : selectedTab === 'searchInsights' ? (
                                <div dangerouslySetInnerHTML={{ __html: selectedRequest.response }} />
                            ) : (
                                <p>Select a request and a tab to view details</p>
                            )
                        ) : (
                            <p>Select a request and a tab to view details</p>
                        )}
                    </div>


    
            </div>
        </div>
    );
    
    
};

export default ActivityLog;
