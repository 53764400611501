import React, { useState } from "react";
import tw from "twin.macro";
import styled, { keyframes, css } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../Homepage/elements/Headings.js";
import { SectionDescription } from "../Homepage/elements/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../Homepage/elements/Buttons.js";
import { Container, ContentWithPaddingXl } from "../Homepage/elements/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../Homepage/images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../Homepage/images/svg-decorator-blob-7.svg";
import { useUser, getUserPlan } from "../../context/UserContext.js"; // Adjust the import path as needed

import axios from 'axios';


// Badge for Hottest Plan
const Badge = styled.span`
  ${tw`absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg`}
  transform: translate(-50%, -50%);
`;

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-2 text-[#07c692] text-lg`;
const Heading = tw(SectionHeading)` text-[#07c692] text-2xl`;
const Description = tw(SectionDescription)`w-full text-center text-sm`;
const SectionContainer = tw(ContentWithPaddingXl)``;
const PlanDurationSwitcher = tw.div`flex flex-col sm:flex-row w-full max-w-xs sm:flex border-2 rounded-full px-1 py-1 mt-4`;
const SwitchButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})`
  ${tw`w-full sm:w-1/2 px-4 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-[#07c692] text-gray-100`}
  border: 2px solid #07c692;

  &:hover {
    border-color: #0a9a7c; 
  }
`;

const PlansContainer = tw.div`flex flex-col items-center md:flex-row md:justify-center relative`;

const Plan = styled.div.withConfig({
  shouldForwardProp: (prop) => !['highlighted', 'animated', 'isHottest'].includes(prop),
})`
  ${tw`w-full max-w-xs sm:max-w-72 mt-4 md:mr-4 text-center rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}
  
  /* Static border if highlighted */
  ${props => props.highlighted && `
    border: 4px solid ${props.isHottest ? '#ff4500' : '#07c692'}; /* Use your custom color scheme */
    position: relative;
    z-index: 10; /* Ensure it is not being hidden */
  `};

  /* Animated border if animated */
  ${props => props.animated && css`
    border: 4px solid #ff9999; /* Start with faint red */
    animation: ${borderGlow} 2s infinite; /* Animate to bright red */
    z-index: 20; /* Ensure the animated border is not hidden */
  `};
`;

const borderGlow = keyframes`
  0% {
    border-color: #ff9999; /* Faint red */
    box-shadow: 0 0 10px rgba(255, 153, 153, 0.7); /* Soft shadow for faint red */
  }
  50% {
    border-color: #ff0000; /* Bright red */
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.9); /* Strong shadow for bright red */
  }
  100% {
    border-color: #ff9999; /* Faint red */
    box-shadow: 0 0 10px rgba(255, 153, 153, 0.7); /* Soft shadow for faint red */
  }
`;



const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-2 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-lg`}
  }
  .price {
    ${tw`font-bold text-3xl sm:text-4xl my-1`}
  }
  .slash {
    ${tw`text-lg text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest text-sm`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-xs font-medium tracking-wide`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col px-4 py-2 flex-1 text-sm`}
  .feature {
    ${tw`mt-2 first:mt-0 leading-relaxed font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-4`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-3 w-full text-sm hover:shadow-xl bg-[#07c692] transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  border: 2px solid #07c692;
  &:hover {
    border-color: #0a9a7c; 
  }
`;

const CurrentPlanSection = styled.div`
  ${tw`text-center border-2 border-[#07c692] p-4 rounded-lg mt-4`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-32 w-32 fill-[#07c692] opacity-25 transform -translate-x-2/3 -translate-y-1/2 text-teal-300`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-32 w-32 fill-[#07c692] opacity-25 transform translate-x-2/3 translate-y-1/2 text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Choose the right plan for your needs. Whether you’re just starting or ready to scale, Quick AI has a solution for everyone.",
  plans = null,
  primaryButtonText = "Get Started",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Free",
      durationPrices: ["$0", "$0"],
      mainFeature: "For Personal Use",
      features: ["Plain Text Analysis", "Limited API Tokens 1000", "Content Length Limitation", "Notification Icon", "Basic Support"]
    },
    {
      name: "Basic",
      durationPrices: ["$9", "$99"],
      mainFeature: "Ideal for Small Teams",
      features: ["All Free Features", "Limited API Tokens 2000", "Text Styles Options", "Text Tone Options", "Priority Support"]
    },
    {
      name: "Pro",
      durationPrices: ["$29", "$299"],
      mainFeature: "Suited for Growing Businesses",
      features: ["All Basic Features", "Unlimited API Tokens", "Advanced Text Analysis", "Context Options", "Priority Support"],
      featured: true
    },
    {
      name: "Enterprise",
      durationPrices: ["$49", "$599"],
      mainFeature: "Customized for Large Enterprises",
      features: ["All Pro Features", "Custom Solutions", "File Attachments", "Multiple Response Option", "Priority Support"],
    }
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const { user, isSignedIn, userPlan } = useUser(); // Get user and sign-in status from UserContext


  const handlePayment = async (plan) => {
    if (!isSignedIn) {
      alert('You must be signed in to purchase a plan.');
      return;
    }
  
    if (plan.name === "Free") {
      // Redirect directly to the success page for the Free plan
      const queryParams = new URLSearchParams({ email: user.email, plan: plan.name }).toString();
      window.location.href = `/success?${queryParams}`;
      return;
    }
  
    try {
      const response = await axios.post('https://server.quickinsights-ai.com/api/auth/checkout/create-payment-intent', {
        price: activeDurationIndex === 0 ? parseFloat(plan.durationPrices[0].replace('$', '')) : parseFloat(plan.durationPrices[1].replace('$', '')),
        planName: plan.name,
        email: user.email,
      });
  
      const { sessionId } = response.data;
  
      // Redirect to Stripe Checkout using the sessionId
      const stripe = window.Stripe('pk_test_51QPOoUFPsrUjmpfjMwMHuSl2Q68tYNyqlzTc9W8FpEbbNx2Lq0LvAKnfC89cLByDmLzILL8bi4pjAAxmc3E7SoWW00ChfPkVSz');
      const { error } = await stripe.redirectToCheckout({ sessionId });
  
      if (error) {
        console.error(error);
        alert('Payment failed');
      }
    } catch (error) {
      console.error('Error during payment process:', error);
      alert('An error occurred during payment processing.');
    }
  };
  


  // Logic to determine if the user is in Free, Basic, or Enterprise plans
  const isUserOnBasicOrFree = userPlan === "Free" || userPlan === "Basic";
  const isUserOnEnterprise = userPlan === "Enterprise";

  return (
    <SectionContainer id="pricing" style={{ padding: '0px' }}>
      <Container>
        <ContentWithPaddingXl>
          <HeaderContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <PlanDurationSwitcher>
              {planDurations.map((planDuration, index) => (
                <SwitchButton
                  active={activeDurationIndex === index}
                  key={index}
                  onClick={() => setActiveDurationIndex(index)}
                >
                  {planDuration.switcherText}
                </SwitchButton>
              ))}
            </PlanDurationSwitcher>
          </HeaderContainer>

         {/* Current Plan Section */}
         {!isSignedIn ? (
          <CurrentPlanSection>
            <h2>Please sign in to view your current plan.</h2>
          </CurrentPlanSection>
        ) : (
          <CurrentPlanSection>
            <h2>Your Current Plan: <strong style={{ color: '#07c692' }}>{userPlan}</strong></h2>
          </CurrentPlanSection>
        )}

        <PlansContainer>
          {plans.map((plan, index) => {
            const isProPlan = plan.name === "Pro";
            const isCurrentUserPlan = plan.name === userPlan;

            const buttonText = `Get ${plan.name}`;
            const shouldShowButton = !isSignedIn || !isCurrentUserPlan;

            return (
              <Plan
                key={index}
                highlighted={isSignedIn && isCurrentUserPlan} // User's plan is highlighted
                animated={isProPlan} // Pro plan is always animated
                isHottest={isProPlan} // Mark Pro plan as hottest
              >
                {isProPlan && <Badge>Hottest Plan!</Badge>}

                <PlanHeader>
                  <span className="priceAndDuration">
                    <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
                    <span className="slash"> / </span>
                    <span className="duration">{planDurations[activeDurationIndex].text}</span>
                  </span>
                  <span className="name">{plan.name}</span>
                  <span className="mainFeature">{plan.mainFeature}</span>
                </PlanHeader>
                <PlanFeatures>
                  {plan.features.map((feature, index) => (
                    <span key={index} className="feature">
                      {feature}
                    </span>
                  ))}
                </PlanFeatures>
                <PlanAction>
                  {shouldShowButton && (
                    <BuyNowButton onClick={() => handlePayment(plan)}>
                      {buttonText}
                    </BuyNowButton>
                  )}
                </PlanAction>
              </Plan>
            );
          })}
        </PlansContainer>


        </ContentWithPaddingXl>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </Container>
    </SectionContainer>
  );
};
