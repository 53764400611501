import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "../components/FiveColumnWithInputForm.js";
import ContactUsForm from "../components/TwoColContactUsWithIllustrationFullForm.js";

import { motion } from "framer-motion";
import styled from "styled-components";
import { Link, useLocation } from 'react-router-dom';

import logo from "../images/quickinsights-logo-black.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";



const HeaderWrapper = styled.div`
  width: 100%; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
`;

const Header = styled.header`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto pt-0 mt-0 px-4`} 
  padding-bottom: 20px;
`;



export const NavLinks = tw.div`flex flex-col  lg:flex-row lg:justify-center`;

const NavLink = styled.a`
  ${tw`text-lg my-2 lg:text-lg lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:no-underline`}
  color: #333; 
  text-decoration: none;
  &:hover {
    color: #07c692; 
  }
`;

const PrimaryLink = tw(NavLink)`
  lg:mx-0 px-8 py-3 rounded bg-[#07c692] text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline no-underline
`;



const LogInButton = styled.a`
  border: 1px solid #999;
  color: #333;
  background-color: #f4f4f4;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 1rem;  

  &:hover {
    border-color: #000;
    color: #07c692;
  }
`;

// Define GetStartedButton with specific styles using styled-components
const GetStartedButton = styled.a`
  background-color: #07c692;
  color: white;
  padding: 0.95rem 1.5rem;
  border-radius: 1.975rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    background-color: #333;
    color: #white;
  }
`;




const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-64 mr-3`}
    margin-left: -40px; 
  }
`;

const MobileNavLinksContainer = tw.nav`
  flex flex-1 items-center justify-between lg:hidden  
`;

const NavToggle = tw.button`
  lg:hidden z-50 focus:outline-none text-white border-white transition duration-300
`;
const MenuIconStyled = tw(MenuIcon)`
  w-6 h-6 fill-current text-white
`;

const CloseIconStyled = tw(CloseIcon)`
  w-6 h-6 fill-current text-white
`;

const MobileNavLinks = motion.create(styled.div`
  ${tw`lg:hidden fixed top-0 left-0 w-full max-w-screen-xl bg-white z-50 p-8`}
  border: 1px solid rgba(0, 0, 0, 0.1); /* Light border */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 50vh; /* Ensure at least 50% of the screen height */
  max-height: calc(100vh - 16px); /* Prevent it from taking full height */
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin-bottom: 1rem; /* Add margin after the menu */
`);


const MobileNavHeader = tw.div`
  flex justify-between items-start w-full mb-8
`;

const MobileMenuLinks = tw.div`
  flex flex-col items-start space-y-6 text-lg
  pl-4  
`;

const MobileNavButtons = tw.div`
  flex flex-col mt-auto items-start space-y-4 w-full pl-4  
`;


const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 items-center justify-between
`;

const DesktopNavContainer = styled.div`
  ${tw`flex items-center justify-end space-x-6`}
  @media (max-width: 1024px) {
    ${tw`justify-start`} 
    }
`;

const MyHeader = ({ links }) => {
  const [showNavLinks, setShowNavLinks] = React.useState(false);

  const toggleNavbar = () => setShowNavLinks((prev) => !prev);

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="Logo" />
    </LogoLink>
  );

  return (
    <HeaderWrapper> {/* Full-width container for border */}
      <Header>
        {/* Desktop menu */}
        <DesktopNavLinks>
          {defaultLogoLink}
          <DesktopNavContainer>
            {links}
            <LogInButton href="/login">Log In</LogInButton>
            <GetStartedButton href="LINK_TO_EXTENSION">Get Started</GetStartedButton>
          </DesktopNavContainer>
        </DesktopNavLinks>

      {/* Mobile menu */}
      <MobileNavLinksContainer>
        {defaultLogoLink}
        <NavToggle onClick={toggleNavbar}>
          {showNavLinks ? <CloseIconStyled /> : <MenuIconStyled />}
        </NavToggle>

        <MobileNavLinks
          initial={{ y: "-100%" }}
          animate={showNavLinks ? { y: "0%" } : { y: "-100%" }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <MobileNavHeader>
            {defaultLogoLink}
            <NavToggle onClick={toggleNavbar}>
              <CloseIconStyled />
            </NavToggle>
          </MobileNavHeader>

          <MobileMenuLinks>{links}</MobileMenuLinks>

          <MobileNavButtons>
            <LogInButton href="/login">Log In</LogInButton>
            <GetStartedButton href="LINK_TO_EXTENSION">Get Started</GetStartedButton>
          </MobileNavButtons>

        </MobileNavLinks>
      </MobileNavLinksContainer>
    </Header>
    </HeaderWrapper>
  );
};


const NavLinksComponent = () => {
  const location = useLocation();  // Get the current location
  const isContactPage = location.pathname === '/contact';  // Check if we're on the contact page

  return (
    <NavLinks key={1}>
      <NavLink as={Link} to="/">Home</NavLink> {/* Home should always link to root */}
      <NavLink href={isContactPage ? '/#features' : '#features'}>Features</NavLink> {/* Adjust anchor links */}
      <NavLink href={isContactPage ? '/#testimonials' : '#testimonials'}>Testimonials</NavLink> {/* Adjust anchor links */}
      <NavLink href={isContactPage ? '/#pricing' : '#pricing'}>Pricing</NavLink> {/* Adjust anchor links */}
      <NavLink as={Link} to="/contact">Contact Us</NavLink> {/* This should always point to /contact */}
    </NavLinks>
  );
};


const Contact = () => {
  return (
    <AnimationRevealPage>
      <MyHeader links={<NavLinksComponent />} />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};

export default Contact;

