import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import './Uploads.css';
import UploadIcon from '../Homepage/images/upload.svg';
import { ReactComponent as PdfIcon } from '../Homepage/images/pdf-icon.svg';
import { ReactComponent as DocxIcon } from '../Homepage/images/docx-icon.svg';
import { ReactComponent as TxtIcon } from '../Homepage/images/txt-icon.svg';
import { ReactComponent as DeleteIcon } from '../Homepage/images/delete-icon.svg';
import { ReactComponent as CalendarIcon } from '../Homepage/images/calendar-icon.svg';

import JSZip from 'jszip';

import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/build/pdf';

// Use the same version for both API and worker
const pdfjsVersion = '2.10.377'; 
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;


const Uploads = () => {
    const { user, userPlan, isSignedIn, signIn } = useUser();
    const [file, setFile] = useState(null);
    const [fileInfo, setFileInfo] = useState(null);
    const [fileContent, setFileContent] = useState("");
    const [recentUploads, setRecentUploads] = useState([]);
    const [lengthOptions, setLengthOptions] = useState({ contentLength: null, wordCount: null });

    // State variables for settings
    const [sliderValue, setSliderValue] = useState(0);
    const [wordCountEnabled, setWordCountEnabled] = useState(false);
    const [wordCount, setWordCount] = useState('');

    useEffect(() => {
        if (isSignedIn && userPlan) {
            loadRecentUploads();
            loadSettingsFromGoogleDrive(); // Load settings when the component mounts
        } else {
            signIn();
        }
    }, [isSignedIn, userPlan, user]);


    const loadSettingsFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
    
        if (!accessToken) {
            console.warn('No access token found. Initiating sign-in...');
            signIn(); // Sign in if token is not available
            return;
        }
    
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="settings_${user.id}.json"&mimeType="application/json"`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            const data = await response.json();
    
            if (data.files && data.files.length > 0) {
                const fileId = data.files[0].id;
    
                const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
    
                if (!fileResponse.ok) {
                    throw new Error('Failed to fetch settings data');
                }
    
                const savedSettings = await fileResponse.json();
    
                // Extract only the relevant settings
                setSliderValue(savedSettings.sliderValue || 0);
                setWordCountEnabled(savedSettings.wordCountEnabled || false);
                setWordCount(savedSettings.wordCount || '');
    
                // Apply the settings to lengthOptions logic
                applySettingsToLengthOptions(savedSettings.sliderValue, savedSettings.wordCountEnabled, savedSettings.wordCount);
            } else {
                console.error('No settings file found.');
            }
        } catch (error) {
            console.error('Error loading settings from Google Drive:', error);
        }
    };
    
    const applySettingsToLengthOptions = (sliderValue, wordCountEnabled, wordCount) => {
       
        if (sliderValue === 0) {
            setLengthOptions({ contentLength: null, wordCount: null });
        } else if (wordCountEnabled && wordCount > 0) {
            setLengthOptions({ contentLength: null, wordCount: parseInt(wordCount, 10) });
        } else {
            switch (sliderValue) {
                case 1:
                    setLengthOptions({ contentLength: 'short', wordCount: null });
                    break;
                case 2:
                    setLengthOptions({ contentLength: 'medium', wordCount: null });
                    break;
                case 3:
                    setLengthOptions({ contentLength: 'long', wordCount: null });
                    break;
                default:
                    setLengthOptions({ contentLength: null, wordCount: null });
                    break;
            }
        }
    };
    

    const loadRecentUploads = async () => {
        const accessToken = localStorage.getItem('accessToken');
    
        if (!accessToken) {
            console.warn('No access token found. Initiating sign-in...');
            return signIn();
        }
    
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q='root'+in+parents+and+trashed=false&fields=files(id,name,mimeType,createdTime,size)`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
    
            const data = await response.json();
    
            if (data.files) {
                // Filter files for only uploaded types
                const uploadedFiles = data.files.filter(file =>
                    ['application/pdf', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.mimeType)
                );
    
                // Map through files to include size and correctly formatted upload time
                const formattedFiles = uploadedFiles.map(file => ({
                    id: file.id,
                    name: file.name,
                    mimeType: file.mimeType,
                    size: (file.size / 1024 / 1024).toFixed(2) + ' MB', // Convert size to MB
                    uploadTime: file.createdTime // Use createdTime directly as it's in ISO format
                }));
    
                setRecentUploads(formattedFiles);
                console.log('Recent uploads set:', formattedFiles); // Log recent uploads
            } else {
                console.error('No files found.');
            }
        } catch (error) {
            console.error('Error fetching recent uploads:', error);
        }
    };
    
    
    
    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    
        if (selectedFile) {
            // Get file information including size
            setFileInfo({
                name: selectedFile.name,
                size: (selectedFile.size / 1024 / 1024).toFixed(2) + ' MB',
                type: selectedFile.type,
            });
            readFileContent(selectedFile);
    
            // Upload the file to Google Drive (or other storage)
            await uploadFileToGoogleDrive(selectedFile);
        }
    };
    

    const uploadFileToGoogleDrive = async (file) => {
        const accessToken = localStorage.getItem('accessToken');
    
        if (!accessToken) {
            console.warn('No access token found. Initiating sign-in...');
            return signIn();
        }
    
        // Check if file already exists
        const fileExists = await checkIfFileExists(file.name, accessToken);
        if (fileExists) {
            console.warn('File already exists on Google Drive. Skipping upload.');
            return; // Skip upload if file already exists
        }
    
        const metadata = {
            name: file.name,
            mimeType: file.type,
            parents: ['root'], // You can specify a folder ID here
        };
    
        const formData = new FormData();
        formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        formData.append('file', file);
    
        try {
            const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('File uploaded successfully:', data); // Log uploaded file data
    
                // Since the upload response does not include `createdTime`, fetch file metadata to get it
                const fileMetadata = await fetch(`https://www.googleapis.com/drive/v3/files/${data.id}?fields=createdTime`, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
    
                const fileData = await fileMetadata.json();
    
                // Format the createdTime for display
                const uploadTime = new Date(fileData.createdTime).toLocaleString();
    
                const newUpload = {
                    id: data.id,
                    name: data.name,
                    mimeType: data.mimeType,
                    size: (file.size / 1024 / 1024).toFixed(2) + ' MB', // Capture size here
                    uploadTime: uploadTime, // Correctly formatted upload time
                };
    
                setRecentUploads((prevUploads) => [...prevUploads, newUpload]); // Add the new upload to recent uploads directly
            } else {
                console.error('Error uploading file:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
  
// Function to format upload time based on createdTime
const formatUploadTime = (uploadTime) => {
    if (!uploadTime) return 'Invalid Date'; // Fallback in case of missing or invalid date

    const date = new Date(uploadTime);
    if (isNaN(date.getTime())) return 'Invalid Date'; // Handle invalid date

    const options = {
        year: 'numeric',
        month: 'long',   // Full month name
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'  // Includes time zone
    };

    return date.toLocaleString(undefined, options); // Format based on the user's locale
};


    
    
    // Helper function to check if file exists
    const checkIfFileExists = async (fileName, accessToken) => {
        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}" and trashed=false`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
    
            const data = await response.json();
            return data.files && data.files.length > 0; // Return true if file exists
        } catch (error) {
            console.error('Error checking for existing file:', error);
            return false; // Return false if there was an error
        }
    };
    
    
    
    
    const readFileContent = async (file) => {
        const fileType = file.type;
    
        if (fileType === 'text/plain') {
            const reader = new FileReader();
            reader.onload = function (e) {
                const content = e.target.result;
                setFileContent(content);
            };
            reader.readAsText(file);
        } else if (fileType === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = async function (e) {
                const pdfData = new Uint8Array(e.target.result);
                try {
                    const pdf = await getDocument({ data: pdfData }).promise;
                    let textContent = '';
                    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                        const page = await pdf.getPage(pageNum);
                        const text = await page.getTextContent();
                        const pageText = text.items.map(item => item.str).join(' ');
                        textContent += pageText + ' ';
                    }
                    setFileContent(textContent);
                } catch (error) {
                    console.error("Error processing PDF file:", error);
                }
            };
            reader.readAsArrayBuffer(file);
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            const reader = new FileReader();
            reader.onload = async function (e) {
                try {
                    const arrayBuffer = e.target.result;
                    const zip = new JSZip();
                    const doc = await zip.loadAsync(arrayBuffer);
                    const docXml = await doc.file("word/document.xml").async("string");
                    const docText = new DOMParser().parseFromString(docXml, "application/xml").documentElement.textContent;
    
                    setFileContent(docText);
                } catch (error) {
                    console.error("Error processing DOCX file:", error);
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            console.warn("Unsupported file type. Please upload a .txt, .pdf, or .docx file.");
            alert("Unsupported file type. Please upload a .txt, .pdf, or .docx file.");
        }
    };
    
    
    
 
    const getSummary = async () => {
        try {
            console.log('Sending lengthOptions:', lengthOptions); // Ensure correct lengthOptions are being sent
    
            const response = await fetch('https://quickinsights-extension.vercel.app/api/generative', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    text: fileContent,
                    instruction: 'Summarize the content briefly in 2-3 paragraphs, focusing on key points only. Avoid adding extra details or explanations.',
                    lengthOptions, // Include length options in the request
                }),
            });
    
          
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
    
            const data = await response.json();
            console.log('Summary response data:', data); // Log the summary response data
            await typeResponse(data.result); // Call the type function here
        } catch (error) {
            console.error('Failed to retrieve summary:', error);
            alert('Failed to retrieve summary.');
        }
    };
    
    // Function to type out the response in a textarea or output element
    const typeResponse = async (response) => {
        const responseTextArea = document.querySelector('.summary-output p'); // Adjust selector as needed
        responseTextArea.innerHTML = ""; // Clear previous content
    
        let typedResponse = '';
        for (let i = 0; i < response.length; i++) {
            typedResponse += response[i];
            responseTextArea.innerHTML = typedResponse; // Update with typed content
            await new Promise(resolve => setTimeout(resolve, 20)); // Add typing delay
        }
    };

    const getFileIcon = (fileType) => {
        switch (fileType) {
          case 'pdf':
            return <PdfIcon className="file-icon" />;
          case 'docx':
            return <DocxIcon className="file-icon" />;
          case 'txt':
            return <TxtIcon className="file-icon" />;
          default:
            return null;
        }
      };
      const getFileType = (mimeType) => {
        if (mimeType.includes('pdf')) return 'pdf';
        if (mimeType.includes('word')) return 'docx';
        if (mimeType.includes('plain')) return 'txt';
        return null;
    };
    

    
    

      const handleDelete = async (fileId) => {
        const accessToken = localStorage.getItem('accessToken');
    
        if (!accessToken) {
            console.warn('No access token found. Initiating sign-in...');
            return signIn(); // Sign in if token is not available
        }
    
        try {
            // Send a DELETE request to Google Drive to remove the file
            const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
    
            if (response.ok) {
                console.log('File deleted successfully');
                // Update recentUploads state by filtering out the deleted file
                setRecentUploads((prevUploads) => prevUploads.filter(file => file.id !== fileId));
            } else {
                console.error('Failed to delete the file:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting file from Google Drive:', error);
        }
    };
    
    return (
        <div className="uploads-container">
            <div className="upload-header">
                <h2>File Upload</h2>
            </div>
            <div className="upload-icon-section">
                <img src={UploadIcon} alt="Upload Icon" className="upload-icon" />
            </div>
            <div className="upload-section">
                <div className="file-input-column">
                    <label className="file-input-label">
                        <input 
                            type="file" 
                            onChange={handleFileChange} 
                            className="file-input" 
                            accept=".txt, .pdf, .docx"
                        />
                        <span className="upload-btn">
                            <img src={UploadIcon} alt="Upload" /> Upload
                        </span>
                    </label>
                    <p className="upload-instructions">Drag and drop TXT, PDF, DOCX files here, or select files to upload.</p>
                    {fileInfo && (
                        <div className="file-info">
                            <p><strong>File Name:</strong> {fileInfo.name}</p>
                            <p><strong>File Size:</strong> {fileInfo.size}</p>
                            <p><strong>File Type:</strong> {fileInfo.type}</p>
                            <button onClick={getSummary} className="generate-summary-btn">
                                Generate Summary
                            </button>
                        </div>
                    )}
                </div>
                <div className="summary-column">
                    <div className="summary-output">
                        <h3>File Summary:</h3>
                        <p></p> {/* This will be populated with the typed summary */}
                    </div>
                </div>
            </div>

            


{/* Recent Uploads Section */}
<div className="recent-uploads-section">
    <h3>Recent Uploads</h3>
    {recentUploads.length > 0 ? (
        <ul className="recent-uploads-list">
            {recentUploads.map((file) => (
                <li key={file.id} className="upload-item">
                    {getFileIcon(getFileType(file.mimeType))} {/* Display file type icon */}
                    <div className="file-info">
                        <span className="file-name">{file.name}</span> {/* Display file name */}
                        <span className="file-size">{file.size}</span> {/* Display file size */}
                    </div>
                    <div className="upload-time-container">
                        <CalendarIcon className="calendar-icon" />
                        <span className="upload-time">{formatUploadTime(file.uploadTime)}</span> {/* Display formatted upload time */}
                    </div>
                    <button 
                        className="delete-recent" 
                        aria-label={`Delete ${file.name}`}
                        onClick={() => handleDelete(file.id)}
                    >
                        <DeleteIcon className="delete-icon" />
                    </button>
                </li>
            ))}
        </ul>
    ) : (
        <p>No recent uploads found.</p>
    )}
</div>




            
        </div>
    );
};

export default Uploads;
