import React, { useState, useEffect } from 'react';
import './BasicSettings.css'; // Import the CSS file

const BasicSettings = ({ selectedTone, setSelectedTone, textStyle, setTextStyle }) => {
    // Array of tone options for Text Tone Settings
    const tones = ['Professional', 'Friendly', 'Persuasive', 'Empathetic', 'Instructive', 'Humorous', 'None'];


    // Arrays for the options in Text Style Settings
    const complexityOptions = ['Technical', 'Layman', 'None'];
    const voiceOptions = ['Active', 'Passive', 'None'];


    // Function to update text style settings
    const handleTextStyleChange = (field, value) => {
        const updatedStyle = { ...textStyle, [field]: value };
        setTextStyle(updatedStyle);
        localStorage.setItem('textStyleSettings', JSON.stringify(updatedStyle)); // Save changes to local storage
    };

    // Function to save the selected tone
    const handleToneChange = (tone) => {
        setSelectedTone(tone);
        localStorage.setItem('selectedTone', tone); // Save tone to local storage
    };

    return (
        <div className="basic-settings-container">
            {/* Group for Text Style Settings */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Text Style Settings</h4>
                    <p>Define how the text should be structured and formatted.</p>
                </div>
                
                <div className="style-options">
                    {/* Complexity */}
                    <div className="style-group">
                        <label className="style-type-label">Complexity:</label>
                        {complexityOptions.map((option) => (
                            <label key={option} className={`style-label ${textStyle.complexity === option ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="complexity"
                                    value={option}
                                    checked={textStyle.complexity === option}
                                    onChange={() => handleTextStyleChange('complexity', option)}
                                    className="style-radio"
                                />
                                {option}
                            </label>
                        ))}
                    </div>

                    {/* Voice */}
                    <div className="style-group">
                        <label className="style-type-label">Voice:</label>
                        {voiceOptions.map((option) => (
                            <label key={option} className={`style-label ${textStyle.voice === option ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="voice"
                                    value={option}
                                    checked={textStyle.voice === option}
                                    onChange={() => handleTextStyleChange('voice', option)}
                                    className="style-radio"
                                />
                                {option}
                            </label>
                        ))}
                    </div>



                </div>
            </div>

            {/* Group for Text Tone Settings */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Text Tone Settings</h4>
                    <p>Select the emotional quality or attitude of the text.</p>
                </div>
                
                <div className="tone-options">
                    {tones.map((tone, index) => (
                        <label key={index} className={`tone-label ${selectedTone === tone ? 'active' : ''}`}>
                            <input
                                type="radio"
                                name="tone"
                                value={tone}
                                checked={selectedTone === tone}
                                onChange={() => handleToneChange(tone)}
                                className="tone-radio"
                            />
                            {tone}
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BasicSettings;
