import React, { useState, useEffect } from "react";
import { useUser } from '../../context/UserContext';
import "./Referral.css";

const Referral = () => {
  const { user, isSignedIn, signIn, saveUserPlanToFirestore } = useUser();
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [totalInvites, setTotalInvites] = useState(0);
  const referralLink = `https://quickinsights-ai.com/login?ref=${referralCode}`;

  useEffect(() => {
    const fetchReferralCode = async () => {
      if (!isSignedIn || !user) return;

      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) return;

        // Check if the user_plan.json file exists and fetch data
        const listResponse = await fetch(
          "https://www.googleapis.com/drive/v3/files?q=name='user_plan.json'&fields=files(id)",
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const files = await listResponse.json();
        if (files.files.length > 0) {
          const fileId = files.files[0].id;
          const getFileResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (getFileResponse.ok) {
            const fileContent = await getFileResponse.json();
            if (fileContent.referralCode) {
              setReferralCode(fileContent.referralCode);
            }
            if (fileContent.totalInvites) {
              setTotalInvites(fileContent.totalInvites); // Set total invites
            }
            return;
          }
        }

        // Generate a new referral code if it doesn't exist
        saveUserPlanToFirestore({ plan: "Free", totalInvites: 0 }); // Save initial data with 0 invites
      } catch (error) {

        console.error("Error fetching referral code:", error);
      }
    };

    fetchReferralCode();
  }, [isSignedIn, user, saveUserPlanToFirestore]);

  const handleAddEmail = () => {
    if (
      emailInput &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput) &&
      !emails.includes(emailInput)
    ) {
      setEmails([...emails, emailInput]);
      setEmailInput("");
    } else {
      alert("Please enter a valid and unique email address.");
    }
  };

  const handleRemoveEmail = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleSendInvites = async () => {
    if (emails.length === 0) {
      alert("Please add at least one email address.");
      return;
    }
  
    try {
      // Fetch the access token from localStorage
      const accessToken = localStorage.getItem("accessToken");
      console.log("Access Token:", accessToken); // Log the access token for debugging
  
      // Check if the token is missing or expired
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }
  
      // Get user details (name and email) from the context or state
      const { name, email } = user || {}; // Replace `user` with your user context or state variable
  
      if (!name || !email) {
        throw new Error("User details are missing.");
      }
  
      console.log("User details:", { name, email }); // Log user details for debugging
  
      // Send the referral invites to the backend
      const response = await fetch("https://server.quickinsights-ai.com/api/auth/sendReferralInvites", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ emails, inviterName: name, inviterEmail: email }),
      });
  
      console.log("Response from sendReferralInvites API:", response); // Log the response for debugging
  
      if (response.ok) {
        // Update the total invites counter
        const newTotalInvites = totalInvites + emails.length;
        setTotalInvites(newTotalInvites);

        // Save the updated number of total invites to the user's Google Drive
        saveUserPlanToFirestore({ totalInvites: newTotalInvites });

        alert("Invites sent successfully!");
        setEmails([]); // Clear email list after success
      } else {
        const error = await response.json();
        console.error("Failed to send invites:", error);
        alert("Failed to send invites. Please try again.");
      }
    } catch (error) {
      console.error("Error sending invites:", error);
      alert("An error occurred while sending invites.");
    }
  };
  

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    alert("Referral link copied to clipboard!");
  };

  return (
    <div className="referral-page">
      <h2>Refer and Earn</h2>
      <p>Invite your friends to join <strong>QuickInsights</strong> using your referral link below!</p>

      <div className="referral-content">
        {/* Invite by Email Section */}
        <div className="referral-card wide">
          <div className="invite-title">
          <h3>Invite by Email</h3>
          </div>
          <div className="email-input-container">
          <input
            type="text"
            className="referral-email-input"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            placeholder="Enter email address"
          />
            <button className="add-email-button" onClick={handleAddEmail}>Add</button>
          </div>
          <div className="email-list">
            {emails.map((email) => (
              <div key={email} className="email-item">
                <span>{email}</span>
                <button onClick={() => handleRemoveEmail(email)}>×</button>
              </div>
            ))}
          </div>
          <button className="send-email-button" onClick={handleSendInvites}>Send Invites</button>
        </div>

        {/* Share Referral Link Section */}
        <div className="referral-card wide">
        <div className="referral-title">
          <h3>Share Your Referral Link</h3>
          </div>
          <div className="referral-link-container">
            <input type="text" value={referralLink} readOnly />
            <button className="copy-email-button" onClick={handleCopyLink}>Copy Link</button>
          </div>
          <div className="referral-actions">
            <a href={`https://facebook.com/share?u=${referralLink}`} target="_blank" rel="noopener noreferrer" className="social-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1877F2" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
              </svg>
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${referralLink}`} target="_blank" rel="noopener noreferrer" className="social-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#14171A " className="bi bi-twitter-x" viewBox="0 0 16 16">
                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
              </svg>
            </a>
            <a href={`https://www.linkedin.com/shareArticle?url=${referralLink}`} target="_blank" rel="noopener noreferrer" className="social-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  fill="#0077B5" className="bi bi-linkedin" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
              </svg>
            </a>
            <a href={`https://www.tiktok.com/share/link?url=${referralLink}`} target="_blank" rel="noopener noreferrer" className="social-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#EE1D52" className="bi bi-tiktok" viewBox="0 0 16 16">
              <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
            </svg>
            </a>
            <a href={`https://wa.me/?text=${encodeURIComponent(referralLink)}`} target="_blank" rel="noopener noreferrer" className="social-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#25D366" className="bi bi-whatsapp" viewBox="0 0 16 16">
              <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
            </svg>
            </a>
{/*
            <a href={`weixin://dl/officialaccounts?scene=108&need_open_webview=1&url=${encodeURIComponent(referralLink)}`} target="_blank" rel="noopener noreferrer" className="social-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#09B83E" className="bi bi-wechat" viewBox="0 0 16 16">
              <path d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.32.32 0 0 0-.12.366l.218.81a.6.6 0 0 1 .029.117.166.166 0 0 1-.162.162.2.2 0 0 1-.092-.03l-1.057-.61a.5.5 0 0 0-.256-.074.5.5 0 0 0-.142.021 5.7 5.7 0 0 1-1.576.22M9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.6.6 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.63.63 0 0 0 .098.356"/>
              <path d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.5.5 0 0 0-.032.14.19.19 0 0 0 .193.193q.06 0 .111-.029l1.268-.733a.6.6 0 0 1 .308-.088q.088 0 .171.025a6.8 6.8 0 0 0 1.625.26 4.5 4.5 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02l.15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826m4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0m3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0"/>
            </svg>
            </a>*/}
          </div>
        </div>

        {/* Total Invites Section */}
        <div className="referral-card">
        <div className="earnings-title">
          <h3>Total Invites</h3>
          </div>
          <p>You have invited<strong> {totalInvites} </strong>people so far!</p>
          
        </div>
      </div>
    </div>
  );
};

export default Referral;
