import React from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "../elements/Headings";
import { SectionDescription } from "../elements/Typography";
import { PrimaryButton as PrimaryButtonBase } from "../elements/Buttons";
import { Container, ContentWithVerticalPadding } from "../elements/Layouts";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as ChromeIcon } from "../images/chrome-icon.svg";  // Add Chrome icon
import { ReactComponent as QuotesLeftIconBase } from "../images/quotes-l.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/dot-pattern.svg";

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center max-w-screen-2xl mx-auto sm:px-24`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = styled(SectionHeading)`
  ${tw`text-left leading-snug xl:text-5xl`}
  color: #333; 
  animation: ${fadeIn} 0.6s ease-out;
`;
const Description = styled(SectionDescription)`
  ${tw`mt-4 lg:text-base max-w-lg`}
  color: #333; // Changed to main color
`;

const PrimaryButton = styled(PrimaryButtonBase)`
  margin-top: 2rem;
  display: inline-block;
  width: 100%;
  max-width: 24rem; // Set max width for large screens
  text-align: center;
  padding: 1.25rem;
  background-color: #07c692; // Main color
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.125rem; // Larger text size
  font-weight: bold;  // Bold text
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #333; // Hover background color
    transform: scale(1.05);  // Slight scale effect on hover
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;  // Space between icon and text

  // Responsive design adjustments
  @media (max-width: 640px) {
    padding: 1rem;  // Adjust padding for smaller screens
    font-size: 1rem;  // Smaller font size for mobile
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    max-width: 18rem;  // Set width for medium screens (tablets, etc.)
  }
`;



const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = styled.li`
  ${tw`flex items-center rounded-lg p-2 transition duration-200 ease-in-out`}
  &:hover {
    background-color: rgba(7, 198, 146, 0.2); // Light main color on hover
  }
`;
const FeatureIcon = styled(CheckboxIcon)` ${tw`w-5 h-5 text-primary-500`}
color: #07c692;
`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`; // Kept this color for contrast
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-[#07c692] text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const QuotesLeftIcon = tw(QuotesLeftIconBase)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const Quote = tw.blockquote``;
const CustomerName = tw.p`mt-4 font-bold text-white`;
const CustomerCompany = tw.p`mt-1 text-sm text-gray-200`;

export default ({
  heading = "Boost Your Productivity with QuickInsights!",
  description = "Unlock the power of AI to analyze any webpage's content with just a click. Get instant insights, suggestions, and summaries, all designed to save you time and improve your workflow.",
  imageSrc = "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://chrome.google.com/webstore/detail/quickinsights",
  primaryButtonText = "Add Extension to Chrome",
  buttonRounded = true,
  features = [
    "Analyze Webpages Instantly",
    "AI-Powered, Context-Aware Suggestions",
    "Streamlined Text Summaries and Insights",
    "Supports Multiple Content Types and Languages",
    "User-Friendly, No Learning Curve"
  ],
  testimonial = {
    quote: "QuickInsights has transformed how I interact with content on the web!",
    customerName: "Jane Doe",
    customerCompany: "Web Analytics Pro"
  }
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
 
  return (
    <>
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton as="a" href={primaryButtonUrl} css={buttonRoundedCss}>
                <ChromeIcon /> {/* Chrome icon */}
                {primaryButtonText}
              </PrimaryButton>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
                <Testimonial>
                  <QuotesLeftIcon />
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerName>{testimonial.customerName}</CustomerName>
                  <CustomerCompany>{testimonial.customerCompany}</CustomerCompany>
                </Testimonial>
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
