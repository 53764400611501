import React, { useState } from "react";
import tw from "twin.macro";
import styled, { keyframes, css } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../elements/Headings.js";
import { SectionDescription } from "../elements/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "../elements/Buttons.js";
import { Container, ContentWithPaddingXl } from "../elements/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-7.svg";



// Define the keyframes for the border animation
const borderGlow = keyframes`
  0% {
    border-color: #07c692;
    box-shadow: 0 0 10px rgba(7, 198, 146, 0.7);
  }
  50% {
    border-color: #0a9a7c;
    box-shadow: 0 0 20px rgba(10, 154, 124, 0.9);
  }
  100% {
    border-color: #07c692;
    box-shadow: 0 0 10px rgba(7, 198, 146, 0.7);
  }
`;

// Badge for Featured Plan
const Badge = styled.span`
  ${tw`absolute top-0 left-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-br-lg`}
  transform: translate(-50%, -50%);
`;


const HeaderContainer = tw.div`w-full flex flex-col items-center`;

const Subheading = tw(SubheadingBase)`mb-4 text-[#07c692]`; 

// Wrapper for heading, subheading, and description
const HeaderWrapper = styled.div`
  ${tw`w-full text-center `}
`;

const Heading = tw(SectionHeading)`text-[#333]`;
const Description = tw(SectionDescription)`w-full text-center text-gray-700 max-w-none`;


const SectionContainer = tw(ContentWithPaddingXl)``;
const PlanDurationSwitcher = tw.div`flex w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 mx-1 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${({ $active }) => $active && tw`bg-[#07c692] text-gray-100`}
  border: 2px solid #07c692;
  &:hover {
    background-color:#333;
    border-color: #0a9a7c; 
    color: white;
  }
`;


const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  /* Highlight and animation for Pro Plan */
  ${({ $isHighlighted }) => $isHighlighted && css`
    border: 4px solid #07c692;
    animation: ${borderGlow} 2s infinite;
    z-index: 20;
  `}

  /* If featured */
  ${({ $featured }) => $featured && `${tw`border-2 border-gray-200 shadow-none`}`}
`;



const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl bg-[#07c692] transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
  border: 2px solid #07c692;
  &:hover {
    background-color:#333;
    border-color: #0a9a7c; 
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 fill-[#07c692] opacity-25 transform -translate-x-2/3 -translate-y-1/2 text-teal-300`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 fill-[#07c692] opacity-25 transform translate-x-2/3 translate-y-1/2 text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Choose the right plan for your needs. Whether you’re just starting or ready to scale, Quick AI has a solution for everyone.",
  plans = null,
  primaryButtonText = "Get Started",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Free Plan",
      durationPrices: ["$0", "$0"],
      mainFeature: "For Personal Use",
      features: ["Plain Text Analysis", "Limited API Tokens 1000", "Content Length Limitation", "Notification Icon", "Basic Support"]
    },
    {
      name: "Basic Plan",
      durationPrices: ["$19", "$199"],
      mainFeature: "Ideal for Small Teams",
      features: ["All Free Features", "Limited API Tokens 2000", "Text Styles Options", "Text Tone Options", "Priority Support"]
    },
    {
      name: "Pro Plan",
      durationPrices: ["$39", "$399"],
      mainFeature: "Suited for Growing Businesses",
      features: ["All Basic Features",  "Unlimited API Tokens", "Advanced Text Analysis", "Context Options", "Priority Support"],
      featured: true
    },
    {
      name: "Enterprise Plan",
      durationPrices: ["$99", "$999"],
      mainFeature: "Customized for Large Enterprises",
      features: ["All Pro Features", "Custom Solutions", "File Attachments", "Multiple Response Option", "Priority Support"],
    }
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <SectionContainer id="pricing">
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
        <HeaderWrapper>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          </HeaderWrapper>
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
             <SwitchButton $active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>
             {planDuration.switcherText}
           </SwitchButton>
           
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
  {plans.map((plan, index) => (
    <Plan key={index} $featured={plan.featured} $isHighlighted={plan.name === "Pro Plan"}>
      {plan.name === "Pro Plan" && <Badge>Featured</Badge>}
  
      <PlanHeader>
        <span className="priceAndDuration">
          <span className="price">{plan.durationPrices[activeDurationIndex]}</span>
          <span className="slash"> / </span>
          <span className="duration">{planDurations[activeDurationIndex].text}</span>
        </span>
        <span className="name">{plan.name}</span>
        <span className="mainFeature">{plan.mainFeature}</span>
      </PlanHeader>
      <PlanFeatures>
        {plan.features.map((feature, index) => (
          <span key={index} className="feature">
            {feature}
          </span>
        ))}
      </PlanFeatures>
      <PlanAction>
        <BuyNowButton>{primaryButtonText}</BuyNowButton>
      </PlanAction>
    </Plan>
  ))}
        </PlansContainer>

      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
    
    </SectionContainer>
  );
};
