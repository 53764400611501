import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-9.svg";
import { ReactComponent as ChromeIcon } from "../images/chrome-icon.svg"; // Import the Chrome icon
import { ContentWithPaddingXl, Container } from "../elements/Layouts";
import { PrimaryButton as PrimaryButtonBase } from "../elements/Buttons";


// Main container with styling
const PrimaryBackgroundContainer = styled.div`
  padding: 4rem 0; /* py-16 */
  @media (min-width: 1024px) {
    padding: 5rem 0; /* lg:py-20 */
  }
  background-color: #07c692; /* bg-[#07c692] */
  border-radius: 0.5rem; /* rounded-lg */
  position: relative;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* shadow-lg */
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05); /* hover:scale-105 */
  }
`;

// Row container with flex layout and spacing
const Row = styled.div`
  padding: 0 1rem; /* px-4 */
  @media (min-width: 640px) {
    padding: 0 4rem; /* sm:px-16 */
  }
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* justify-between */
  align-items: center;
  position: relative;
  z-index: 10;
`;

// Left section for heading and subheading
const LeftColumn = styled.div`
  width: 100%; /* w-full */
  margin-bottom: 2rem; /* mb-8 */
  @media (min-width: 1024px) {
    width: 50%; /* lg:w-1/2 */
    margin-bottom: 0; /* lg:mb-0 */
  }
`;

// Subheading text with white color
const Subheading = styled.h3`
  color: white; /* text-white */
  font-size: 1.25rem; /* Adjust font size */
  font-weight: 500; /* Light font-weight */
  margin-bottom: 0.5rem; 
    &:hover {
    color: white;
    transition: color 0.3s ease-in-out;
  }
`;

const Heading = styled.h2`
  color: white;
  font-weight: bold;
  font-size: 1.875rem; /* 3xl equivalent */
  line-height: 1.3;
  text-align: left;

  @media (min-width: 640px) {
    font-size: 2.25rem; /* 4xl equivalent */
  }

  @media (min-width: 1024px) {
    font-size: 3rem; /* 5xl equivalent */
  }

  &:hover {
    color: white;
    transition: color 0.3s ease-in-out;
  }
`;


// Right section for subscribe and extension link
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: 50%; /* lg:w-1/2 */
    align-items: flex-end;
  }
`;

// Subscribe section container
const SubscribeSection = styled.div`
  width: 100%;
  max-width: 20rem; /* w-full max-w-sm */
  @media (min-width: 1024px) {
    max-width: 28rem; /* lg:max-w-md */
  }
`;

// Column heading
const ColumnHeading = styled.h3`
  text-transform: uppercase; /* uppercase */
  font-weight: bold; /* font-bold */
  color: white; /* text-white */
  margin-bottom: 1rem; /* mb-4 */
  text-align: center;
    &:hover {
    color: white;
    transition: color 0.3s ease-in-out;
  }
  @media (min-width: 1024px) {
    text-align: left; /* lg:text-left */
  }
`;

// Subscribe form
const SubscribeForm = styled.form`
  display: flex;
  flex-direction: column; /* flex-col */
  width: 100%; /* w-full */
  align-items: center;
  @media (min-width: 640px) {
    flex-direction: row; /* sm:flex-row */
    align-items: stretch; /* sm:items-stretch */
  }
`;

// Input field
const Input = styled.input`
  flex-grow: 1; /* flex-grow */
  width: 100%; /* w-full */
  background-color: white; /* bg-white */
  color: #333; /* text-[#333] */
  border-radius: 0.5rem; /* rounded */
  padding: 0.75rem 1rem; /* px-4 py-3 */
  border: 2px solid transparent;
  &:focus {
    outline: none;
    border-color: #333; /* focus:border-[#333] */
  }
  @media (min-width: 640px) {
    border-radius: 0.5rem 0 0 0.5rem; /* sm:rounded-r-none */
  }
`;

// Subscribe button
const SubscribeButton = styled.button`
  margin-top: 0.75rem; /* mt-3 */
  width: 50%; /* w-full */
  padding: 0.75rem 2rem; /* px-8 py-3 */
  background-color:#333;
  color: #fff;
  font-weight: bold; /* font-bold */
  border: 2px solid #333; /* border-[07c692] */
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #333; /* hover:bg-[#333] */
    color: white; /* hover:text-white */
    transform: scale(1.05); /* hover:scale-105 */
  }
  @media (min-width: 640px) {
    margin-top: 0; /* sm:mt-0 */
    border-radius: 0 0.5rem 0.5rem 0; /* sm:rounded-l-none */
  }
`;


const PrimaryLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem; /* 16 in Tailwind equivalent */
  font-size: 0.875rem; /* sm:text-base equivalent */
  padding: 0.75rem 1.5rem; /* px-6 py-3 equivalent */
  font-weight: bold;
  border-radius: 9999px; /* rounded-full */
  border: none;
  background-color: white;
  color: black;
  text-decoration: none;
  letter-spacing: 0.05em; /* tracking-wide */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow-md */
  transition: all 0.3s ease-in-out;
  gap: 0.5rem; /* Space between the icon and the text */

  &:hover {
    background-color: black;
    color: white;
    transform: scale(1.05); /* Adds a subtle zoom effect */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Slightly bigger shadow on hover */
  }

  @media (max-width: 640px) {
    width: 100%; /* Make it full width on smaller screens */
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 
    text-[#b9ffda] opacity-25 transition-transform duration-[6s] ease-in-out`}
`;

const DecoratorBlob2 = styled(SvgDecoratorBlob1)`
  ${tw`absolute top-0 right-0 w-80 h-80 transform translate-x-20 -translate-y-64 
    text-[#b9ffda] opacity-25 transition-transform duration-[6s] ease-in-out`}
`;

export default ({
  subheading = "Excited about QuickInsights?",
  heading = "Get your Productivity Rocking Today!",
  primaryLinkText = "Install Extension Now",
  primaryLinkUrl = "#", // Add the Chrome extension link here
  pushDownFooter = true,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            {/* Left Section */}
            <LeftColumn>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
            </LeftColumn>

            {/* Right Section */}
            <RightColumn>
              {/* Subscribe Section */}
              <SubscribeSection>
                <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
                <SubscribeForm method="get" action="#">
                  <Input type="email" placeholder="Your Email Address" />
                  <SubscribeButton type="submit">Subscribe</SubscribeButton>
                </SubscribeForm>
              </SubscribeSection>

              {/* Install Extension Link */}
              <PrimaryLink href={primaryLinkUrl}>
                <ChromeIcon tw="w-6 h-6" />
                <span>{primaryLinkText}</span>
              </PrimaryLink>
            </RightColumn>
          </Row>
          {/* Decorative Background Blobs */}
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

