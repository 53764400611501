// src/components/RecentActivity.js
import React, { useEffect, useState } from 'react';
import './styles/recentActivity.css';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

import { useUser } from '../context/UserContext';

const RecentActivity = () => {
    const [recentActivity, setRecentActivity] = useState([]);
    const [totalRequests, setTotalRequests] = useState(0);
    const [totalUploads, setTotalUploads] = useState(0);
    const { user, isSignedIn, signIn, userPlan  } = useUser();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isSignedIn && user) {
            loadActivityLogFromGoogleDrive();
            loadRecentUploads();
        } else {
            setLoading(false); // Stop loading if user is not signed in
        }
    }, [user, isSignedIn, signIn]);

    const loadActivityLogFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.warn('No access token found. Please sign in.');
            return;
        }

        try {
            const fileName = `${user.id}_requests.json`;
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const data = await response.json();

            if (data.files && Array.isArray(data.files) && data.files.length > 0) {
                const fileId = data.files[0]?.id;

                if (!fileId) {
                    console.warn('File ID not found in the response.');
                    return;
                }

                const fileResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                if (!fileResponse.ok) {
                    throw new Error('Failed to fetch activity log data');
                }

                const logData = await fileResponse.json();

                if (logData && logData.requests) {
                    const allRequests = Object.values(logData.requests).flat();

                    // Ensure all responses are strings or valid arrays
                    const cleanedRequests = allRequests.map((req) => ({
                        ...req,
                        response: Array.isArray(req.response)
                            ? req.response.filter(res => typeof res === 'string')
                            : typeof req.response === 'string' ? req.response : null,
                    }));

                    const latestRequests = cleanedRequests
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .slice(0, 10);

                    setRecentActivity(latestRequests);
                    setTotalRequests(allRequests.length);
                } else {
                    console.warn('No requests found in the activity log data.');
                    setRecentActivity([]);
                    setTotalRequests(0);
                }
            } else {
                console.warn('No activity log found for this user. Creating a new one.');
                setRecentActivity([]);
                setTotalRequests(0);
            }
        } catch (error) {
            console.error('Error loading activity log from Google Drive:', error);
        }
    };

    const loadRecentUploads = async () => {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            console.warn('No access token found. Initiating sign-in...');
            return;
        }

        try {
            const response = await fetch(`https://www.googleapis.com/drive/v3/files?q='root'+in+parents+and+trashed=false&fields=files(id,name,mimeType,createdTime,size)`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const data = await response.json();

            if (data.files) {
                const uploadedFiles = data.files.filter(file =>
                    ['application/pdf', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.mimeType)
                );

                setTotalUploads(uploadedFiles.length);
            } else {
                console.error('No files found.');
            }
        } catch (error) {
            console.error('Error fetching recent uploads:', error);
        }
    };

    // Data for the bar chart
    const barData = {
        labels: recentActivity.map((activity) => 
            activity.date ? new Date(activity.date).toLocaleString() : 'Unknown Date'
        ),
        datasets: [{
            label: 'Latest Requests',
            data: recentActivity.map((activity) => {
                const response = activity.response;
                if (typeof response === 'string') {
                    // Calculate word count for string responses
                    const trimmedResponse = response.trim();  // Remove leading/trailing whitespace
                    return trimmedResponse.split(/\s+/).length;  // Use regex to handle multiple spaces
                } else if (Array.isArray(response)) {
                    // Optional: Calculate total word count for array responses
                    return response
                        .filter(res => typeof res === 'string')
                        .reduce((count, res) => count + res.trim().split(/\s+/).length, 0);
                }
                return 0; // Default to 0 for invalid or missing responses
            }),
            backgroundColor: '#07c692',
            borderColor: '#07c692',
            borderWidth: 1,
            borderRadius: 10,
        }]
    };

    // Data for the pie chart showing total requests made vs total files uploaded
    const pieData = {
        labels: ['Total Requests', 'Total Files Uploaded'],
        datasets: [{
            label: 'Activity Overview',
            data: [totalRequests, totalUploads],
            backgroundColor: ['#07c692', '#FFA500'],
            hoverOffset: 10,
        }]
    };

    return (
        <div className="recent-activity">
            <h3>Recent Activity</h3>
            <div className="chart-container">
                {/* Bar Chart for Recent Activity */}
                <div className="chart-item">
                    <Bar
                        data={barData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    grid: { display: false },
                                    title: {
                                        display: true,
                                        text: 'Date',
                                        color: '#333',
                                        font: { size: 14 },
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    title: {
                                        display: true,
                                        text: 'Word Count',
                                        color: '#333',
                                        font: { size: 14 },
                                    }
                                }
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => {
                                            const activity = recentActivity[tooltipItem.dataIndex];
                                            const response = activity.response;
                                
                                            
                                            // Ensure response is a string before calling .trim and .split
                                            if (typeof response === 'string') {
                                                const trimmedResponse = response.trim();
                                                const wordCount = trimmedResponse.split(/\s+/).length; // Ensure correct word count
                                
                                                const responseWords = trimmedResponse !== ''
                                                    ? trimmedResponse.split(/\s+/).slice(0, 4).join(' ') 
                                                    : '';
                                
                                                return [`${responseWords}`, `Word Count: ${wordCount}`];
                                            } else if (Array.isArray(response)) {
                                                // Handle array responses
                                                const allResponses = response.filter(res => typeof res === 'string');
                                                const wordCount = allResponses.reduce((count, res) => count + res.trim().split(/\s+/).length, 0);
                                
                                                const responseWords = allResponses.length > 0 
                                                    ? allResponses.slice(0, 4).join(' ') 
                                                    : '';
                                
                                                return [`${responseWords}`, `Word Count: ${wordCount}`];
                                            } else {
                                                // If response is neither a string nor an array, return default values
                                                return ['No response', 'Word Count: 0'];
                                            }
                                        }
                                    }
                                }
                                ,
                                legend: { display: false },
                            }
                        }}
                    />
                    {recentActivity.length === 0 && (
                        <p className="no-data-overlay">No recent activity yet.</p>
                    )}
                </div>

                {/* Pie Chart for Total Requests vs Uploaded Files */}
                <div className="chart-item">
                    <Pie
                        data={pieData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => {
                                            return `${tooltipItem.label}: ${tooltipItem.raw}`;
                                        }
                                    }
                                },
                                legend: {
                                    position: 'bottom',
                                    labels: { color: '#333' },
                                }
                            },
                        }}
                    />
                    {(totalRequests === 0 && totalUploads === 0) && (
                        <p className="no-data-overlay">No activity data available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecentActivity;
