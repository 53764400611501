// src/components/APITokenLimit.js
import React, { useEffect, useState } from 'react';
import './styles/apiTokenLimit.css';
import { useUser, db } from '../context/UserContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const APITokenLimit = () => {
    const { user,isSignedIn, userPlan, saveUserPlanToFirestore } = useUser();
    const plan = userPlan || 'Free';
    const [loading, setLoading] = useState(true);

    const tokenLimits = {
        Free: 5000,
        Basic: 10000,
        Pro: 10000, // Defined token limit for Pro's "Search Insights"
        Enterprise: Infinity,
    };

    const [tokensUsed, setTokensUsed] = useState(0);
    const [insightsTokensUsed, setInsightsTokensUsed] = useState(0);
    const tokenLimit = tokenLimits[plan];
    const tokensRemaining = plan === 'Pro' ? tokenLimits.Pro - insightsTokensUsed : tokenLimit - tokensUsed;
    const insightsTokensRemaining = plan === 'Pro' ? tokenLimits.Pro - insightsTokensUsed : 0;
    const progress = tokenLimit === Infinity ? 0 : (tokensUsed / tokenLimit) * 100;
    const insightsProgress = plan === 'Pro' ? (insightsTokensUsed / tokenLimits.Pro) * 100 : 0;

    const getProgressBarColor = (progressValue) => {
        if (progressValue <= 50) return '#07c692';
        if (progressValue <= 75) return '#FFA500';
        return '#FF4500';
    };
    useEffect(() => {
        const fetchTokens = async () => {
            if (user && user.email) {
                try {
                    const userDocRef = doc(db, 'users', user.email);
                    const userDocSnapshot = await getDoc(userDocRef);

                    if (userDocSnapshot.exists()) {
                        const userData = userDocSnapshot.data();
                        setTokensUsed(userData.tokensUsed || 0);
                        setInsightsTokensUsed(userData.insightsTokensUsed || 0);
                    } else {
                        console.log('No user data found, initializing tokens to 0.');
                    }
                } catch (error) {
                    console.error('Error fetching tokens from Firestore:', error);
                }
            }
        };

        fetchTokens();
    }, [user]);
    useEffect(() => {
        // Create WebSocket connection
        const ws = new WebSocket('wss://server.quickinsights-ai.com'); // Replace with actual WebSocket server URL
        
        // Connection opened
        ws.onopen = () => {
            console.log('WebSocket connected in dashboard for apitokens');
        };
    
        // Listen for messages
        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);

                if (data.action === 'updateTokens') {
                    console.log('Received token update from WebSocket:', data);

                    const { tokensUsed} = data;
        
                    // Call the save function from UserContext to save token usage
                    
                    if (user && user.email) {
                        console.log(
                            `Saving tokens to Firestore: tokensUsed=${tokensUsed}, insightsTokensUsed=${insightsTokensUsed}`
                        );
                        saveUserPlanToFirestore(
                            user.email,
                            plan, 
                            tokensUsed || 0,
                            insightsTokensUsed || 0,
                            db
                        );
                    }
                }
           
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };
        ws.onerror = (error) => console.error('WebSocket error:', error);
        ws.onclose = () => console.log('WebSocket disconnected');

        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [user, userPlan]);
    
 
    useEffect(() => {
        if (isSignedIn && user) {
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [user, isSignedIn]);

    return (
        <div className="api-token-limit">
            <h3>{plan === 'Pro' ? 'Search Insights Token Usage' : 'API Token Usage'}</h3>
            {loading ? (
            <div className="spinner"></div>
            ) : plan === 'Enterprise' ? (
                <p>Unlimited usage available</p>
            ) : (
                <div>
                    {(plan === 'Free' || plan === 'Basic') && (
                        <div className="progress-bar-container standard-bar">
                            <div
                                className="progress-bar standard-progress-bar"
                                style={{ width: `${progress}%`, backgroundColor: getProgressBarColor(progress) }}
                            />
                            <div className="marker orange" style={{ left: '55%' }} />
                            <div className="marker red" style={{ left: '90%' }} />
                        </div>
                    )}

                    {plan === 'Pro' && (
                        <div className="progress-bar-container insights-bar">
                            <div
                                className="progress-bar insights-progress-bar"
                                style={{ width: `${insightsProgress}%`, backgroundColor: getProgressBarColor(insightsProgress) }}
                            />
                            <div className="marker orange" style={{ left: '55%' }} />
                            <div className="marker red" style={{ left: '90%' }} />
                        </div>
                    )}
                    <div className="api-token-info">
                        <p>
                            <strong>{plan === 'Pro' ? insightsTokensUsed : tokensUsed}</strong> tokens used
                            {plan === 'Pro' && ` out of ${tokenLimits.Pro}`}
                        </p>
                        <p>
                            <strong>{plan === 'Pro' ? insightsTokensRemaining : tokensRemaining}</strong> Search tokens remaining
                        </p>
                    </div>


                    {(progress > 90 || insightsProgress > 90) && (
                        <p style={{ color: 'red' }}>You're nearing your limit! Consider upgrading your plan for more tokens.</p>
                    )}

                    {(plan === 'Free' || plan === 'Basic' || (plan === 'Pro' && insightsProgress >= 50)) && (
                        <div className="card upgrade-prompt">
                            <h4>Upgrade Your Plan</h4>
                            <p>Upgrade to our highest plan for unlimited tokens everywhere!</p>
                            <a href="/billing" className="upgrade-button" style={{ textDecoration: 'none' }}>Upgrade Now</a>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default APITokenLimit;





  /*
    const loadUserRequestsFromGoogleDrive = async () => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            console.warn('No access token found. Please sign in.');
            setLoading(false);
            return;
        }
    
        try {
            const fileName = `${user.id}_requests.json`;
            const response = await fetch(
                `https://www.googleapis.com/drive/v3/files?q=name="${fileName}"&mimeType="application/json"`, 
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
    
            const data = await response.json();
            if (data.files && data.files.length > 0) {
                const fileId = data.files[0].id;
    
                const fileResponse = await fetch(
                    `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }
                );
    
                if (!fileResponse.ok) throw new Error('Failed to fetch activity log data');
    
                const logData = await fileResponse.json();
    
                if (logData && logData.requests) {
                    // Flatten all responses and filter for valid strings
                    const allResponses = Object.values(logData.requests).flatMap((requestArray) =>
                        requestArray.flatMap((request) => {
                            const responses = Array.isArray(request.response) ? request.response : [request.response];
                            return responses.filter((res) => typeof res === 'string' && res.trim() !== '');
                        })
                    );
    
                    calculateTokenUsage(allResponses);
    
                    // Handle Pro plan-specific Search Insights data
                    if (plan === 'Pro') {
                        const searchInsights = Object.values(logData.requests).flatMap((requestArray) =>
                            requestArray.filter((request) => request.category === 'searchInsights')
                        );
    
                        if (searchInsights.length > 0) {
                            const searchResponses = searchInsights.flatMap((insight) => {
                                // Ensure response exists and is valid
                                if (insight.response) {
                                    if (Array.isArray(insight.response)) {
                                        // Join array responses into a single string
                                        return [insight.response.join("\n")];
                                    } else if (typeof insight.response === 'string') {
                                        return [insight.response];
                                    }
                                }
                                console.warn('Invalid response format in search insights:', insight.response);
                                return [];
                            });
    
                            if (searchResponses.length > 0) {
                                calculateSearchInsightsUsage(searchResponses);
                            } else {
                                console.warn('No valid responses found in the search insights category.');
                            }
                        } else {
                            console.warn('No search insights found in the activity log data.');
                        }
                    }
                } else {
                    console.warn('No requests found in the activity log data.');
                }
            } else {
                console.warn('No activity log found for this user.');
            }
        } catch (error) {
            console.error('Error loading activity log from Google Drive:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const calculateTokenUsage = (responses) => {
        let totalWordCount = 0;
    
        responses.forEach((response) => {
            const wordCount = response.split(/\s+/).length; // Count words
            totalWordCount += wordCount;
        });
    
        const totalTokensUsed = Math.ceil(totalWordCount * 1.33);
        setTokensUsed(totalTokensUsed);
    
    };
    
    const calculateSearchInsightsUsage = (searchInsights) => {
        let insightsWordCount = 0;
    
        searchInsights.forEach((response) => {
            if (typeof response === 'string') {
                const wordCount = response.split(/\s+/).length; // Count words
                insightsWordCount += wordCount;
            } else {
                console.warn('Invalid response format in search insights:', response);
            }
        });
    
        const insightsTokensUsed = Math.ceil(insightsWordCount * 1.33);
        setInsightsTokensUsed(insightsTokensUsed);
    
    };
    
    
    
 */
   
   