import React, { useState, useEffect } from 'react';
import './FreeSettings.css'; // Import the CSS file

const FreeSettings = ({ 
    sliderValue, 
    setSliderValue, 
    wordCountEnabled, 
    setWordCountEnabled, 
    wordCount, 
    setWordCount,
    notificationsEnabled,
    setNotificationsEnabled
}) => {
    
    // Effect to set word count to 0 if word count is disabled
    useEffect(() => {
        if (!wordCountEnabled) {
            setWordCount(0);
        }
    }, [wordCountEnabled, setWordCount]);

    return (
        <div className="settings-container">

            {/* Notifications Group */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Notifications</h4>
                    <p>Get notified with an icon upon highlighting text.</p>
                </div>
                <label className="toggle-label" htmlFor="notificationToggle">
                    <span className={`toggle ${notificationsEnabled ? 'active' : ''}`}>
                        <span className="toggle-circle"></span>
                    </span>
                    <span className={`toggle-text ${notificationsEnabled ? 'on' : 'off'}`}>
                        {notificationsEnabled ? 'On' : 'Off'}
                    </span>
                    <input
                        type="checkbox"
                        id="notificationToggle"
                        checked={notificationsEnabled}
                        onChange={(e) => setNotificationsEnabled(e.target.checked)}
                        className="toggle-input"
                    />
                </label>
            </div>

            {/* Group: Content Length/Word Count Settings */}
            <div className="group-container">
                <div className="group-title">
                    <h4>Content Settings</h4>
                    <p>Adjust the content length and enable word count limits.</p>
                </div>

                <div className={`length-settings ${wordCountEnabled ? 'contentblurred' : ''}`}>
                    <label htmlFor="lengthSlider" className="label">Limit Content Length:</label>
                    <input
                        type="range"
                        id="lengthSlider"
                        min="0"
                        max="3"
                        step="1"
                        value={wordCountEnabled ? 0 : sliderValue} // Use 0 or the current value if word count is enabled
                        onChange={(e) => setSliderValue(Number(e.target.value))}
                        className="range-slider"
                        disabled={wordCountEnabled} // Disable the slider if word count is enabled
                    />
                    <div className="slider-label">
                        <span>No Limit</span>
                        <span>Short</span>
                        <span>Medium</span>
                        <span>Long</span>
                    </div>
                    
                    {wordCountEnabled && <div className="disabled-overlay">Disabled</div>} {/* Disabled message */}
                </div>

                <label className="label">
                    <input
                        type="checkbox"
                        id="enableWordCount"
                        checked={wordCountEnabled}
                        onChange={(e) => setWordCountEnabled(e.target.checked)}
                        className="checkbox"
                    />
                    Enable Word Count Limit
                </label>

                {wordCountEnabled && (
                    <div className="word-count-input">
                        <label htmlFor="wordCount" className="label">Limit Word Count:</label>
                        <input
                            type="number"
                            id="wordCount"
                            placeholder="Enter word count"
                            value={wordCount}
                            onChange={(e) => setWordCount(e.target.value)}
                            className="input-field"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FreeSettings;
