import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "../elements/Headings.js";
import { SectionDescription } from "../elements/Typography.js";

import defaultCardImage from "../images/shield-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../images/svg-decorator-blob-7.svg";

// Import icons
import SupportIconImage from "../images/support.svg";
import ShieldIconImage from "../images/security.svg";
import CustomizeIconImage from "../images/options.svg";
import FastIconImage from "../images/fast.svg";
import SimpleIconImage from "../images/user.svg";


// Container styles
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;

const Subheading = tw(SubheadingBase)`mb-4 text-[#07c692]`; 

// Wrapper for heading, subheading, and description
const HeaderWrapper = styled.div`
  ${tw`w-full text-center mb-12`}
`;

const Heading = tw(SectionHeading)`text-[#333]`;
const Description = tw(SectionDescription)`w-full text-center text-gray-700 max-w-none`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8 bg-gray-200 rounded-lg shadow-md`}
  
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
      fill: #07c692; 
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl text-gray-800`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-gray-600 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 fill-[#07c692] text-[#07c692]`} 
`;

export default ({ 
  cards = null, 
  heading = "Amazing Features", 
  subheading = "What QuickInsights Offers", 
  description = "QuickInsights provides powerful tools for analyzing highlighted text and enhancing productivity."
}) => {
  
  const defaultCards = [
    { 
      imageSrc: SupportIconImage, 
      title: "24/7 Support", 
      description: "Our dedicated team is always ready to assist you with any questions." 
    },
    {
      imageSrc: SupportIconImage,
      title: "Customizable Content Settings",
      description: "Adjust content length and word count limits to suit your needs."
    },
    {
      imageSrc: ShieldIconImage,
      title: "Full Page Insights",
      description: "Get insights for the entire page content directly from the extension."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Advanced Features for Pro Users",
      description: "Unlock additional settings such as Gmail replies and multiple responses."
    },
    {
      imageSrc: FastIconImage,
      title: "Instant Suggestions & Summaries",
      description: "Receive quick suggestions and concise text summaries for better productivity."
    },
    {
      imageSrc: SimpleIconImage,
      title: "Seamless Integration",
      description: "Easily integrate QuickInsights with your workflow to enhance productivity."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <div id="features">
      <Container>
        <ThreeColumnContainer>
          <HeaderWrapper>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderWrapper>
          
          <VerticalSpacer />
          
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc || defaultCardImage} alt={card.title || "Feature"} />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title || "Feature Title"}</span>
                  <p className="description">
                    {card.description || "Feature description goes here."}
                  </p>
                </span>
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
        <DecoratorBlob />
      </Container>
    </div>
  );
};
