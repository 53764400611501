import React, { useState, useEffect } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../elements/Layouts.js";
import tw from "twin.macro";
import styled from "styled-components";
import illustration from "../images/login-page.png";
import logo from "../images/quickinsights-logo-dark.png";
import googleIconImageSrc from "../images/google-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";


const Container = tw(ContainerBase)`min-h-screen bg-[#f4f9f4] text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-40`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h2`text-2xl xl:text-3xl font-extrabold text-[#333]
`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  cursor: pointer;
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-6`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-[#07c692] border-[#07c692] text-gray-100 w-full py-4 rounded-lg hover:bg-[#07c692] transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  cursor: pointer;
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3 text-lg`}
  }
`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1  text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imagesrc}");`}
  ${tw` w-full bg-contain bg-center bg-no-repeat`}
`;



// Google OAuth 2.0 configuration
const googleClientId = '1038698941000-g2qlach0atplvgn6feqe55es06q9eq6j.apps.googleusercontent.com';
const googleRedirectUri = 'https://app.quickinsights-ai.com/';
const scopes = 'openid email profile https://www.googleapis.com/auth/drive.file';
const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${googleClientId}&redirect_uri=${googleRedirectUri}&response_type=token&scope=${scopes}`;

// Main Login Component
export default function Login({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Sign In to QuickInsights",
}) {
  const [email, setEmail] = useState("");
  const [attemptedSignIn, setAttemptedSignIn] = useState(false); 
 
  useEffect(() => {
    const currentUrl = window.location.href;
  
    // Check if the user is already signed in and redirect to dashboard
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      window.location.href = "/dashboard";  // Redirect if the user is already signed in
      return; // Exit the effect early to prevent further checks
    }
  
    // Only handle the redirect if the attempted sign-in is true
    if (currentUrl.startsWith(googleRedirectUri) && attemptedSignIn) {
      handleOAuthRedirect(currentUrl);  // Handle OAuth token extraction
    }
  
    // Set up event listener for messages from the extension
    const handleAuthSuccess = (event) => {
      if (event.source !== window) return; // Only accept messages from the current window
      if (event.data.action === 'authSuccess') {
        console.log('Token received in web app:', event.data.token);
      }
    };
  
    window.addEventListener("message", handleAuthSuccess);
  
    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("message", handleAuthSuccess);
    };
  }, [attemptedSignIn]);
  
  // Function to initiate Google sign-in
  const signInWithGoogle = () => {
    setAttemptedSignIn(true);  // Set flag to true when the sign-in button is clicked
    window.location.href = authUrl;  // Redirect to Google sign-in
  };

  // Function to handle the OAuth redirect and extract tokens from Google
  const handleOAuthRedirect = (url) => {
    const fragmentParams = new URLSearchParams(url.split('#')[1] || '');
    const accessToken = fragmentParams.get('access_token');

    if (accessToken) {
      sendAccessTokenToBackend(accessToken);  // Send the token to the backend
    } else {
      console.error("Failed to obtain access token from Google.");  // Log error only if sign-in was attempted
    }
  };

  // Function to send access token to backend and receive JWT token
  const sendAccessTokenToBackend = (accessToken) => {
    fetch('https://server.quickinsights-ai.com/api/auth/signin/google', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const token = data.token;  // Extract the JWT token from the response
        localStorage.setItem("authToken", token);  // Store token for web app use
        
        // Optionally redirect to another page after token is sent
        window.location.href = "/dashboard";
      } else {
        console.error('Authentication failed:', data.error);  // Log error for authentication failure
      }
    })
    .catch(error => {
      console.error('Error sending access token to backend:', error.message);
      console.error("An error occurred during authentication.");  // Log error for general failures
    });
  };

// Function to send magic link for authentication
const sendMagicLink = async (e) => {
  e.preventDefault();
  console.log("Sending magic link to:", email);

    // Store the email in localStorage for future retrieval
    localStorage.setItem("emailForSignIn", email);
  try {
    const response = await fetch("https://server.quickinsights-ai.com/api/auth/signin/magic-link", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (data.success) {
      console.log("Magic link sent to your email!");
      alert("Please check your email for the magic link.");
    } else {
      console.error("Error:", data.error);
    }
  } catch (error) {
    console.error("Failed to send magic link:", error);
  }
};


  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  <SocialButton onClick={signInWithGoogle}>
                    <span className="iconContainer">
                      <img src={googleIconImageSrc} className="icon" alt="Google Icon" />
                    </span>
                    <span className="text">Sign In With Google</span>
                  </SocialButton>
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign in with your e-mail</DividerText>
                </DividerTextContainer>
                <Form onSubmit={sendMagicLink}>
                  <Input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <SubmitButton type="submit">
                    <LoginIcon className="icon" />
                    <span className="text">Send Quick Link</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imagesrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}
